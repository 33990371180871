<template lang="pug">
    .products-sidebar(ref="sidebar")
        .active-category.forMobile_or_p(@click="openRazdels")
            .active-category__razdel {{ activeCategory.razdel }}
            hr
            .active-category__name {{ activeCategory.name }}

        .razdels(:class="{ opened: razdelsIsOpened }")
            .razdels-top.forMobile_or_p
                app-button(@click="closeRazdels")
                    closer-icon

            .razdel(v-for="razdel in menu")
                .razdel-name(@click="changeRazdel(razdel.id)")
                    .razdel-name__icon
                    span {{ razdel.name }}
                .razdel-list(ref="razdelList")
                    .razdel-list__wrapper(ref="razdelListWrapper")
                        template(v-for="item in razdel.children")
                            .subrazdel(v-if="item.children && item.children.length")
                                .subrazdel-name.category(
                                    @click.stop="toggleSubrazdel($event, razdel.id)"
                                ) {{ item.name }}
                                hr
                                .subrazdel-list
                                    .subrazdel-list__wrapper
                                        template(v-for="subrazdelItem in item.children")
                                            .category(
                                                :data-category-id="subrazdelItem.id"
                                                :class="{ active: activeCategoryId === subrazdelItem.id }"
                                                @click.stop="changeCategory(subrazdelItem)"
                                            ) {{ subrazdelItem.name }}
                                            hr
                            template(v-else)
                                .category(
                                    :data-category-id="item.id"
                                    :class="{ active: activeCategoryId === item.id }"
                                    @click.stop="changeCategory(item)"
                                ) {{ item.name }}
                                hr
</template>

<script>
import closerIcon from '@/assets/images/icons/closer.svg?inline';
import { cloneDeep } from 'lodash';

export default {
    name: 'products-sidebar',

    components: {
        closerIcon,
    },

    data: () => ({
        activeRazdelId: null,
        activeCategoryId: null,

        razdelsIsOpened: false,
    }),

    props: {
        lastSelectedCategoryFilter: {
            type: String,
            default: '',
        },
    },

    watch: {
        lastSelectedCategoryFilter(value) {
            const razdelId = this.menu.find((r) => r.slug === value)?.id;

            this.changeRazdel(razdelId, false);
        },
    },
    computed: {
        menu() {
            const menu = this.$store.getters.menu;

            // menu.forEach(menuItem => menuItem.isCollapsed = false);

            return menu.map((r) => {
                const rClone = cloneDeep(r);
                rClone.children.unshift({
                    name: 'Все товары',
                    id: rClone.id,
                    slug: rClone.slug,
                    isCommon: true,
                });
                return rClone;
            });
        },

        activeRazdel() {
            return this.menu.find((r) => r.id === this.activeRazdelId);
        },

        allSubrazdels() {
            return this.menu.reduce((allRazdels, razdel) => {
                return allRazdels.concat(
                    razdel.children.reduce((all, child) => {
                        return all.concat(child).concat(child.children || []);
                    }, []),
                );
            }, []);
        },

        activeCategory() {
            return {
                razdel: this.activeRazdel?.name,
                name: this.allSubrazdels.find((c) => c.id === this.activeCategoryId)?.name,
            };
        },
    },

    async mounted() {
        await this.$store.dispatch('getMenu');
        this.checkActiveCategory();

        window.addEventListener('resize', this.resizeHandler);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
    },

    methods: {
        changeRazdel(id, needChangeCategory = true) {
            const razdelIndex = this.menu.findIndex((r) => r.id === id);
            this.activeRazdelId = id;

            const razdel = this.$refs.sidebar.querySelectorAll(`.razdel`)[razdelIndex];
            if (razdel) {
                const list = razdel.querySelector('.razdel-list');
                const wrapper = list.querySelector('.razdel-list__wrapper');

                if (!razdel.classList.contains('active')) {
                    const activeCategory = razdel.parentElement.querySelector('.razdel.active');
                    if (activeCategory) {
                        const activeCategoryList = activeCategory.querySelector('.razdel-list');
                        activeCategoryList.style.height = 0;
                        activeCategory.classList.remove('active');
                    }
                    list.style.height = wrapper.scrollHeight + 'px';
                    razdel.classList.add('active');
                }

                if (needChangeCategory) {
                    this.changeCategory(this.menu[razdelIndex].children[0]);
                }
            }
        },
        wait(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },

        updateRazdelHeight() {
            const razdel = this.$refs.sidebar.querySelector('.razdel.active');
            const list = razdel.querySelector('.razdel-list');
            const wrapper = list.querySelector('.razdel-list__wrapper');
            list.style.height = wrapper.scrollHeight + 'px';
        },
        async toggleSubrazdel(e, razdelId) {
            const razdelIndex = this.menu.findIndex((r) => r.id === razdelId);

            const razdel = this.$refs.sidebar.querySelectorAll(`.razdel`)[razdelIndex];
            const list = razdel.querySelector('.razdel-list');
            const listWrapper = razdel.querySelector('.razdel-list__wrapper');

            const subrazdel = e.target.closest('.subrazdel');
            if (subrazdel) {
                const name = subrazdel.querySelector('.subrazdel-name');
                const list = subrazdel.querySelector('.subrazdel-list');
                const wrapper = subrazdel.querySelector('.subrazdel-list__wrapper');

                if (!subrazdel.classList.contains('active')) {
                    list.style.height = wrapper.scrollHeight + 'px';
                    name.classList.add('active');
                    subrazdel.classList.add('active');
                } else {
                    list.style.height = 0;
                    name.classList.remove('active');
                    subrazdel.classList.remove('active');
                }
            }

            await this.wait(400);

            list.style.height = listWrapper.scrollHeight + 'px';
        },

        changeCategory(item) {
            if (this.activeCategoryId === item.id) return;

            this.activeCategoryId = item.id;
            if (this.$route.query.category !== item.slug) {
                this.$router.replace({
                    query: {
                        ...this.$route.query,
                        category: item.slug,
                    },
                });
            }

            // this.$refs.razdelList.style.height = `${this.$refs.razdelListWrapper.clientHeight}px`;

            this.$emit('changeCategory', item);

            if (!this.$store.getters.menu.some((r) => r.id === item.id)) {
                this.closeRazdels();
            }
        },

        checkActiveCategory() {
            const categorySlug = this.$route.query.category;
            if (categorySlug) {
                // ищем активный раздел с такой категорией с таким слагом
                const razdel = this.menu.find((razdel) => {
                    const allRazdelChildren = razdel.children.reduce((all, child) => {
                        return all.concat(child).concat(child.children || []);
                    }, []);
                    return allRazdelChildren.some((i) => i.slug === categorySlug);
                });
                if (razdel) {
                    // Ищем категорию с таким слагом
                    const item = this.allSubrazdels.find((r) => r.slug === categorySlug);
                    this.changeRazdel(razdel.id, false);
                    this.changeCategory(item);
                    // открываем subrazdel, если активная категория в нем
                    const activeCategoryElem = this.$refs.sidebar.querySelector(
                        `[data-category-id="${item.id}"]`,
                    );
                    const activeCategoryElemSubrazdel = activeCategoryElem.closest('.subrazdel');
                    if (activeCategoryElemSubrazdel) {
                        const subrazdelName =
                            activeCategoryElemSubrazdel.querySelector('.subrazdel-name');
                        subrazdelName.click();
                    }
                    return;
                }
            }
            this.changeRazdel(this.menu[0]?.id);
        },

        openRazdels() {
            this.razdelsIsOpened = true;
            if (window.isMobileOrP) {
                window.disableScroll();
            }
        },

        closeRazdels() {
            this.razdelsIsOpened = false;
            if (window.isMobileOrP) {
                window.enableScroll();
            }
        },

        resizeHandler() {
            if (this.razdelsIsOpened) {
                if (window.isMobileOrP) {
                    window.disableScroll();
                } else {
                    window.enableScroll();
                }
            }
            this.updateRazdelHeight();
        },
    },
};
</script>

<style scoped lang="scss">
.products-sidebar {
    flex-shrink: 0;

    width: rem(234px);

    .razdel {
        &-name {
            display: inline-flex;
            align-items: center;

            margin-bottom: rem(46px);

            cursor: pointer;
            opacity: 0.5;
            transition: opacity 0.2s;

            &__icon {
                position: relative;

                width: rem(24px);
                height: rem(24px);
                margin-right: rem(17px);

                border: rem(2px) solid $black;
                border-radius: 50%;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    width: rem(10px);
                    height: rem(2px);

                    background: $black;
                    border-radius: rem(2px);

                    transition: opacity 0.2s;
                }

                &:before {
                    transform: translate(-50%, -50%);
                }

                &:after {
                    transform: translate(-50%, -50%) rotate(90deg);
                }
            }

            span {
                font-size: rem(20px);
                line-height: 1.3;
                font-weight: 500;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        &.active .razdel-name {
            pointer-events: none;
        }

        &-list {
            height: 0;
            //height: initial !important;
            overflow: hidden;

            transition: height 0.4s;

            hr {
                width: calc(100% - #{rem(41px)});
                margin-bottom: rem(23px);
                margin-left: rem(41px);
            }
        }

        &.active {
            .razdel-name {
                opacity: 1;

                &__icon:after {
                    opacity: 0;
                }
            }
        }
    }

    .subrazdel {
        &-name {
            width: 100%;

            &::after {
                content: '';

                width: rem(10px);
                height: rem(10px);
                margin-left: auto;

                background: url(../../../assets/images/icons/arrow-bottom-2.svg) no-repeat center
                    center / contain;

                opacity: 0.5;
                transition: transform 0.1s;
            }

            &.active::after {
                transform: rotate(180deg);
                opacity: 1;
            }
        }

        &-list {
            height: 0;
            padding-left: rem(30px);
            overflow: hidden;

            transition: height 0.4s;

            &__wrapper {
                padding-bottom: 1px;
            }
        }
    }

    .category {
        display: inline-flex;
        align-items: center;

        margin-bottom: rem(22px);

        font-size: rem(14px);
        line-height: rem(16px);

        cursor: pointer;

        user-select: none;

        &:before {
            content: '';

            width: rem(24px);
            height: rem(11px);
            margin-right: rem(17px);

            background: url(../../../assets/images/icons/arrow.svg) no-repeat center center / auto
                100%;

            opacity: 0;
            transition: opacity 0.2s;
        }

        /*&:last-of-type {
            margin-bottom: rem(60px);

            & + hr {
                display: none;
            }
        }*/

        &:hover {
            &:before {
                opacity: 0.5;
            }
        }

        &.active {
            font-weight: 500;

            &:before {
                opacity: 1;
            }
        }
    }

    @include mobile_or_P {
        width: 100%;

        .active-category {
            position: relative;

            max-width: 20rem;
            margin: 0 auto rem(38px);
            padding: rem(7px) rem(35px) rem(14px) rem(12px);

            border: 1px solid $black;

            &__razdel {
                font-size: rem(20px);
                line-height: 1.28;
                font-weight: 500;
            }

            hr {
                margin: rem(12px) 0 rem(13px);
            }

            &__name {
                font-size: rem(16px);
                line-height: rem(22px);
                font-weight: 500;
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: rem(14px);

                width: rem(10px);
                height: rem(10px);

                background: url(../../../assets/images/icons/arrow-bottom-2.svg) no-repeat center
                    center / contain;

                transform: translateY(-50%);
            }
        }

        .razdels {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;

            padding: rem(13px) rem(10px);
            overflow: auto;

            background: #fff;

            transform: translate3d(-100%, 0, 0);
            opacity: 0;
            transition: opacity 0.3s, transform 0s 0.3s;

            &-top {
                display: flex;
                justify-content: flex-end;

                .app-button {
                    width: auto;
                    margin: 0;
                    padding: 0 rem(8px);

                    clip-path: none;

                    svg {
                        margin: 0;

                        transform: translateX(-1px);
                    }
                }
            }

            &.opened {
                transform: initial;
                opacity: 1;
                transition: opacity 0.3s;
            }
        }

        .razdel {
            max-width: 20rem;
            margin: 0 auto;

            &-name {
                width: 100%;
            }

            &.active {
                .razdel-name {
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: calc(100% + #{rem(20px)});
                        right: 0;
                        left: 0;

                        height: 2px;

                        background: url(../../../assets/images/divider.svg) repeat-x 0 bottom / auto
                            100%;
                    }
                }
            }

            &-list {
                hr {
                    width: rem(193px);
                    margin-left: 0;
                }
            }
        }

        .category {
            width: rem(193px);

            &:before {
                display: none;
            }
        }
    }
}
</style>
