<template lang="pug">
    .app-count-picker
        .app-count-picker__button.minus(@click="$emit('minus')")
        .app-count-picker__count {{ count }}
        .app-count-picker__button.plus(@click="$emit('plus')")
</template>

<script>
export default {
    name: 'app-count-picker',

    props: {
        count: {
            type: Number,
            required: true,
        },
    },
};
</script>
