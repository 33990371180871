<template lang="pug">
    .favorites-toggler(:class="classes")
        app-button(
            transparent
            @click="toggle"
        )
            heart-filled-icon(v-if="isInFavorites")
            heart-not-filled-icon(v-else)
</template>

<script>

import heartNotFilledIcon from '@/assets/images/icons/heart-not-filled.svg?inline';
import heartFilledIcon from '@/assets/images/icons/heart-filled.svg?inline';

export default {
    name: 'favorites-toggler',

    components: {
        heartNotFilledIcon,
        heartFilledIcon,
    },

    props: {
        productId: {
            type: String,
            required: true,
        },

        small: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        isInFavorites() {
            return this.$store.getters['favorites/favorites'].includes(this.productId);
        },

        classes() {
            return { small: this.small };
        },
    },

    methods: {
        toggle() {
            if (this.isInFavorites) {
                this.$store.dispatch('favorites/removeFromFavorites', this.productId);
            } else {
                this.$store.dispatch('favorites/addToFavorites', this.productId);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.favorites-toggler {
    .app-button {
        padding: 0 rem(12px);

        border: none;

        clip-path: none;

        svg {
            width: rem(21px);
            margin-top: 0;
            margin-right: 0;

            @media screen and (width: 768px) {
                width: 24px;
            }

            @media screen and (width: 375px) {
                width: 24px;
            }
        }

        @media (hover: hover) {
            &:hover {
                background: none;

                svg {
                    fill: $blue;
                }
            }
        }
    }

    &.small {
        .app-button {
            height: initial;
            padding: 0;
        }
    }
}
</style>
