<template lang="pug">
    .default-layout
        layout-new-header
        slot
        layout-new-footer

</template>

<script>
import LayoutNewHeader from '@/components/layout/NewHeader';
import LayoutNewFooter from '@/components/layout/NewFooter';

export default {
    name: 'default-layout',

    components: {
        LayoutNewHeader,
        LayoutNewFooter,
    },
};
</script>
