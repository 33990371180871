<template lang="pug">
    .app-breadcrumbs
        .app-breadcrumbs__items.forDesktop
            .app-breadcrumbs__item(
                v-for="(link, key) in links"
                :key="key"
            )
                router-link.app-breadcrumbs__item-name(v-if="link.to" :to="link.to") {{ link.name }}
                span.app-breadcrumbs__item-name(v-else) {{ link.name }}
                span.app-breadcrumbs__item-separator /

        router-link.app-breadcrumbs__back.forMobile_or_p(:to="backLink")
            img(src="@/assets/images/icons/arrow-back.svg")
            | Назад

</template>

<script>
export default {
    name: 'app-breadcrumbs',

    props: {
        links: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    computed: {
        backLink() {
            return this.$routerHistory.hasPrevious() ? { path: this.$routerHistory.previous().path } : { name: 'Home' };
        },
    },
};
</script>
