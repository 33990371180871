<template lang="pug">
    .sort-dropdown(:class="{ opened: isOpen }")
        app-button.sort-button(
            transparent
            @click="togglePopup"
        )
            sort-icon
            span Сортировать

        transition
            .sort-popup(v-show="isOpen")
                .top.forMobile_or_p
                    span Сортировать
                    app-button(@click="closePopup")
                        closer-icon
                .sort-list
                    template(v-for="sort in sorts")
                        .sort-item(
                            @click="changeSort(sort)"
                            :class="{ active: state.sortBy === sort.sortBy && state.order === sort.order }"
                        ) {{ sort.name }}
                        hr
</template>

<script>
import sortIcon from '@/assets/images/icons/sort.svg?inline';
import closerIcon from '@/assets/images/icons/closer.svg?inline';

import togglePopupMixin from '@/mixins/togglePopupMixin';

export default {
    name: 'sort-dropdown',

    components: {
        sortIcon,
        closerIcon,
    },

    mixins: [togglePopupMixin],

    props: {
        value: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        sorts: [
            {
                name: 'По новизне',
                sortBy: 'time',
                order: 'desc',
            },
            {
                name: 'По возрастанию цены',
                sortBy: 'price',
                order: 'asc',
            },
            {
                name: 'По убыванию цены',
                sortBy: 'price',
                order: 'desc',
            },
        ] ,
    }),

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },
    },

    methods: {
        changeSort(sort) {
            if (this.state.sortBy === sort.sortBy && this.state.order === sort.order) return;

            this.state = {
                sortBy: sort.sortBy,
                order: sort.order,
            };
        },

        clickOutsideHandler(e) {
            if (!e.target.closest('.sort-popup')) {
                this.closePopup();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.sort-dropdown {
    position: relative;
    z-index: 1;

    transition: filter .3s;

    &.opened {
        filter: drop-shadow(0 0 rem(25px) rgba(0, 0, 0, 0.15));

        @include only_safari {
            filter: none;
        }

        .sort-button {
            color: $gray;

            background: #fff;

            svg {
                fill: $gray;
            }

            @media (hover: hover) {
                &:hover {
                    color: $black;

                    svg {
                        fill: $black;
                    }
                }
            }
        }
    }
}

.sort-button {
    padding-right: rem(12px);
    padding-left: rem(12px);

    font-size: rem(12px);

    border: none;

    clip-path: none;

    @include mobile_or_P {
        font-size: rem(10px);
    }
}

.sort-popup {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;

    width: rem(260px);
    padding: 0 rem(19px) rem(10px) rem(44px);

    background: #fff;

    @include only_safari {
        box-shadow: 0 0 rem(16px) rgba($black, 0.1);
    }

    .sort-item {
        position: relative;

        display: inline-flex;

        margin: rem(20px) 0;

        font-size: rem(12px);

        cursor: pointer;

        user-select: none;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: rem(-22px);

            width: rem(11px);
            height: rem(11px);

            background: url(../../assets/images/icons/arrow.svg) no-repeat center center / auto 100%;

            transform: translateY(-50%);
            opacity: 0;
            transition: opacity .2s;
        }

        &:hover {
            &:before {
                opacity: 0.5;
            }
        }

        &.active {
            font-weight: 500;

            &:before {
                opacity: 1;
            }
        }
    }

    hr {
        margin-left: rem(-2px);

        &:last-of-type {
            display: none;
        }
    }
}

@include mobile_or_tablet {
    .sort-popup {
        overflow: auto;
    }
}

@include mobile_or_P {
    .sort-dropdown {
        z-index: initial;

        &.opened {
            filter: none;
        }
    }

    .sort-popup {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: initial;
        padding: rem(13px) rem(10px);

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-bottom: rem(24px);
            padding-left: rem(31px);

            span {
                font-size: rem(20px);
                font-weight: 500;
            }

            .app-button {
                width: auto;
                margin: 0;
                padding: 0 rem(8px);

                clip-path: none;

                svg {
                    margin: 0;

                    transform: translateX(-1px);
                }
            }
        }

        .sort-list {
            max-width: 20rem;
            margin: 0 auto;
            padding: 0 rem(31px);
        }

        .sort-item {
            width: 100%;
        }
    }
}
</style>
