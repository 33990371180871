<template lang="pug">
    .favorites
        .container
            app-breadcrumbs(:links="breadcrumbsLinks")
            .top
                h2 Избранное
                favorites-button
                bin-preview

            app-loader(v-if="!pageLoaded")

            .products(v-else-if="products.length")
                app-product-card(
                    v-for="product in visibleProducts"
                    :key="product.id"
                    :product="product"
                    allow-toggle-favorites
                )
                .products__bottom(v-if="showMoreButton")
                    app-button(@click="page++") Показать ещё

            template(v-else)
                .app-message Вы не добавили в избранное ни одного товара
                br
                app-button(
                    tag="router-link"
                    :to="{ name: 'Products' }"
                ) Перейти к аксессуарам
</template>

<script>
import FiltersDropdown from '@/components/pages/FiltersDropdown';
import SortDropdown from '@/components/pages/SortDropdown';
import FavoritesButton from '@/components/pages/FavoritesButton';
import BinPreview from '@/components/pages/BinPreview';

import { getGroupById } from '@/js/api/requests/catalog';

export default {
    name: 'favorites',

    components: {
        FiltersDropdown,
        SortDropdown,
        FavoritesButton,
        BinPreview,
    },

    data: () => ({
        products: [],

        perPage: 4,
        page: 1,

        pageLoaded: false,
    }),

    computed: {
        favorites() {
            return this.$store.getters['favorites/favorites'];
        },

        visibleProducts() {
            return this.products.slice(0, this.perPage * this.page);
        },

        showMoreButton() {
            return this.products.length > this.perPage * this.page;
        },

        breadcrumbsLinks() {
            return [
                {
                    to: { name: 'Home' },
                    name: 'Главная',
                },
                {
                    to: { name: 'Products' },
                    name: 'Аксессуары HAVAL',
                },
                {
                    name: 'Избранное',
                },
            ];
        },
    },

    created() {
        this.getProducts();
    },

    methods: {
        async getProducts() {
            try {
                this.products = await Promise.all(
                    this.favorites.map(id => {
                        return getGroupById(id);
                    }),
                );
                this.pageLoaded = true;
            } catch (e) {
                console.log(e);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.favorites {
    min-height: calc(100vh - #{rem(51px)});
    padding-bottom: rem(90px);

    .app-breadcrumbs {
        margin-bottom: rem(35px);
    }

    .top {
        display: flex;

        margin-bottom: rem(62px);

        h2 {
            margin-right: auto;
        }

        .favorites-button,
        .bin-preview {
            margin-top: rem(-72px);
        }

        .favorites-button {
            margin-right: rem(12px);
        }
    }

    .products {
        display: flex;
        flex-wrap: wrap;

        .app-product-card {
            width: calc((100% - #{rem(49px)} * 3) / 4);
            margin-bottom: rem(70px);

            &:not(:nth-of-type(4n)) {
                margin-right: rem(49px);
            }
        }

        &__bottom {
            display: flex;
            justify-content: center;

            width: 100%;
        }
    }

    @include mobile_or_P {
        .app-breadcrumbs {
            margin-bottom: rem(20px);
        }

        .top {
            flex-wrap: wrap;

            max-width: 20rem;
            margin: 0 auto rem(62px);

            h2 {
                width: 100%;
            }

            .favorites-button,
            .bin-preview {
                order: -1;

                margin-top: rem(-66px);
            }

            .favorites-button {
                margin-right: rem(7px);
            }
        }

        .products {
            .app-product-card {
                width: 100%;
                margin-bottom: rem(50px);

                &:not(:nth-of-type(4n)) {
                    margin-right: auto;
                }
            }
        }
    }
}
</style>
