import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';

class ModalPlugin extends ScrollbarPlugin {
    static pluginName = 'modal';
    static defaultOptions = {
        open: false,
    };
    transformDelta(delta) {
        return this.options.open ? { x: 0, y: 0 } : delta;
    }
}
Scrollbar.use(ModalPlugin);

class HorizontalScrollPlugin extends Scrollbar.ScrollbarPlugin {
    static pluginName = 'horizontalScroll';
    transformDelta(delta, fromEvent) {
        if (this.options.enable) {
            if (!/wheel/.test(fromEvent.type)) {
                return delta;
            }
            const {x, y} = delta;
            return {
                y: 0,
                x: Math.abs(x) > Math.abs(y) ? x : y,
            };
        }
    }
}
Scrollbar.use(HorizontalScrollPlugin);

window.Scrollbar = Scrollbar;
