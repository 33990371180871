<template>
    <div
        class="dealer-icon"
        :class="`dealer-icon--type--${type}`"
    >
        {{ type }}
    </div>
</template>

<script>
export default {
    name: 'dealer-icon',

    props: {
        type: {
            type: String,
            default: 'city',

            validator(type) {
                return ['city', 'pro'].includes(type);
            },
        },
    },
};
</script>

<style scoped lang="scss">
.dealer-icon {
    padding: rem(5px) 0;

    font-size: rem(18px);
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;

    &--type {
        &--city {
            width: rem(76px);
            height: rem(23px);

            background-color: #00c9bf;
        }

        &--pro {
            width: rem(62px);
            height: rem(23px);

            background-color: #121314;
        }
    }
}
</style>
