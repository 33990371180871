export default {
    namespaced: true,

    state: {
        favorites: [],
    },

    mutations: {
        setFavorites: (state, data) => {
            state.favorites = data;
        },
    },

    actions: {
        async getFavorites({ commit }) {
            try {
                const favorites = localStorage.getItem('favorites');
                if (favorites) {
                    commit('setFavorites', JSON.parse(favorites));
                }
            } catch (e) {
                localStorage.removeItem('favorites');
            }
        },

        addToFavorites({ state, commit }, id) {
            const favorites = [...state.favorites, id] ;
            commit('setFavorites', favorites);
            localStorage.setItem('favorites', JSON.stringify(favorites));
        },

        removeFromFavorites({ state, commit }, id) {
            const favorites = state.favorites.filter(f => f !== id);
            commit('setFavorites', favorites);
            localStorage.setItem('favorites', JSON.stringify(favorites));
        },
    },

    getters: {
        favorites: state => state.favorites,
    },
};
