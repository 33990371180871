<template lang="pug">
    #app
        component(:is="layout")
            keep-alive(include="products" )
                router-view
        i-e-zaglushka
</template>

<script>
import DefaultLayout from '@/layouts/Default';
import TransparentHeaderLayout from '@/layouts/TransparentHeader';
import IEZaglushka from '@/components/other/IEZaglushka';
import { CITIES_URL } from '@/config/citiesUrl';

export default {
    components: {
        DefaultLayout,
        TransparentHeaderLayout,
        IEZaglushka,
    },

    computed: {
        layout() {
            return this.$route.meta.layout || 'default-layout';
        },
    },

    created() {
        this.$store.dispatch('order/getOrder');
        this.$store.dispatch('favorites/getFavorites');
        this.checkUtm();
    },
    mounted() {
        document.querySelectorAll('a').forEach((link) => {
            if (!CITIES_URL.some((city) => link.href.includes(city))) return;

            link.href = `${link.href}?utm_source=haval.ru&utm_medium=dealer_locator`;
        });
    },
    methods: {
        checkUtm() {
            const expectedUtm = [
                'utm_id',
                'utm_source',
                'utm_medium',
                'utm_campaign',
                'utm_content',
                'utm_term',
            ];

            expectedUtm.forEach((utm) => {
                const utmInQuery = this.$route.query[utm];
                if (utmInQuery) {
                    this.$cookies.set(utm, utmInQuery, '30d');
                }
            });
        },
    },
};
</script>
