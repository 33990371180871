<template lang="pug">
    .app-text-field(:class="classes")

        label {{ label }}

        input(
            v-if="!isTextarea"
            v-model="state"
            type="text"
            :placeholder="placeholder"
            :name="name"
            :autocomplete="autocomplete"
            :disabled="disabled"
        )

        textarea-autosize(
            v-else
            v-model="state"
            :placeholder="placeholder"
            :name="name"
            :autocomplete="autocomplete"
            :max-height="1000"
            :disabled="disabled"
        )

        .app-text-field__error(v-if="error" v-html="error")
</template>

<script>

export default {
    name: 'app-text-field',

    props: {
        value: {
            validator: () => true,
            required: true,
        },

        name: {
            type: String,
            required: false,
            default: '',
        },

        autocomplete: {
            type: String,
            required: false,
            default: 'off',
        },

        label: {
            type: String,
            required: false,
            default: '',
        },

        placeholder: {
            type: String,
            required: false,
            default: '',
        },

        isTextarea: {
            type: Boolean,
            required: false,
            default: false,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        error: {
            type: [String, Boolean],
            required: false,
            default: '',
        },
    },

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        classes() {
            return {
                'app-text-field--input': !this.isTextarea,
                'app-text-field--textarea': this.isTextarea,
                'app-text-field--disabled': this.disabled,
                'app-text-field--invalid': this.error,
            };
        },
    },
};
</script>
