<script>
export default {
    name: 'collection-crossway',
};
</script>

<template>
    <div class="collection-crossway">
        <div class="container">
            <div class="collection-crossway__content">
                <div class="collection-crossway__part">
                    <img
                        src="/img/collection-crossway/city-preview.jpg"
                        alt=""
                        class="collection-crossway__image"
                    />

                    <app-button
                        tag="router-link"
                        to="/products?category=city-1&collections=haval_city"
                        active
                        class="collection-crossway__button collection-crossway__button--collection"
                    >
                        Коллекция HAVAL CITY
                    </app-button>
                </div>

                <div class="collection-crossway__part">
                    <img
                        src="/img/collection-crossway/pro-preview.jpg"
                        alt=""
                        class="collection-crossway__image"
                    />

                    <app-button
                        tag="router-link"
                        to="/products?category=pro-1&collections=haval_pro"
                        class="collection-crossway__button collection-crossway__button--collection"
                    >
                        Коллекция HAVAL PRO
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.collection-crossway {
    &__content {
        display: flex;
        gap: rem(80px);

        @include mobile_or_P {
            flex-direction: column;
            gap: rem(25px);
        }
    }

    &__part {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__image {
        max-width: 100%;
        margin-bottom: rem(42px);

        @include mobile_or_P {
            margin-bottom: rem(16px);
        }
    }

    &__button {
        &--collection {
            width: rem(434px);
            height: rem(41px);

            font-size: rem(20px);
            text-transform: unset;
            letter-spacing: 0.08em;

            @include mobile_or_P {
                width: unset;

                font-size: rem(11px);
            }
        }

        &--catalog {
            height: rem(27px);
            margin: 0;

            font-size: rem(12px);
            font-weight: 400;

            @include mobile_or_P {
                width: unset;

                font-size: rem(9px);
            }
        }
    }
}
</style>
