<template lang="pug">
    .ieZaglushka
        h2 Ваш браузер устарел!
        p Вы&nbsp;пользуетесь устаревшей версией браузера Internet Explorer.<br>
            | Данная версия браузера не&nbsp;поддерживает многие настройки и&nbsp;технологические решения, из-за чего страницы и&nbsp;функции могут работать не&nbsp;корректно.
            | Бесплатные версии подходящих браузеров доступны для скачивания:
        .list
            .item
                img(src="@/assets/images/ieZaglushka/firefox.png" alt="")
                h6 Mozilla Firefox
                small Доступно для:
                .platforms
                    img(src="@/assets/images/ieZaglushka/windows.png" alt="")
                    img(src="@/assets/images/ieZaglushka/apple.png" alt="")
                    img(src="@/assets/images/ieZaglushka/linux.png" alt="")
                app-button(href="https://www.mozilla.org/" tag="a" target="_blank") Перейти к загрузке
            .item
                img(src="@/assets/images/ieZaglushka/chrome.png" alt="")
                h6 Google Chrome
                small Доступно для:
                .platforms
                    img(src="@/assets/images/ieZaglushka/windows.png" alt="")
                    img(src="@/assets/images/ieZaglushka/apple.png" alt="")
                    img(src="@/assets/images/ieZaglushka/linux.png" alt="")
                app-button(href="https://www.google.com/chrome/" tag="a" target="_blank") Перейти к загрузке
            .item
                img(src="@/assets/images/ieZaglushka/opera.png" alt="")
                h6 Opera
                small Доступно для:
                .platforms
                    img(src="@/assets/images/ieZaglushka/windows.png" alt="")
                    img(src="@/assets/images/ieZaglushka/apple.png" alt="")
                    img(src="@/assets/images/ieZaglushka/linux.png" alt="")
                app-button(href="https://www.opera.com/ru" tag="a" target="_blank") Перейти к загрузке
            .item
                img(src="@/assets/images/ieZaglushka/safari.png" alt="")
                h6 Safari
                small Доступно для:
                .platforms
                    img(src="@/assets/images/ieZaglushka/apple.png" alt="")
                app-button(href="https://www.apple.com/" tag="a" target="_blank") Перейти к загрузке
</template>

<script>
import AppButton from '@/components/app/app-button/index';

export default {
    name: 'i-e-zaglushka',

    components: {
        AppButton,
    },
};
</script>

<style scoped lang="scss">

.ieZaglushka {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10000000;

    display: none;

    width: 100vw;
    overflow: hidden;

    text-align: center;

    background: #fff;

    h2 {
        margin-bottom: 2.3rem;
        padding-top: 15vh;

        font-size: rem(60px);
        font-weight: 500;
    }

    p {
        max-width: 62rem;
        margin: 0 auto 5.7vh;

        font-size: 1.25rem;
        line-height: 1.4;
        text-align: center;
    }

    .list {
        display: block;

        .item {
            display: inline-block;

            width: 18.3rem;
            margin: 0 0.6rem;
            padding: 3.6vh 1.5rem 4.4vh;

            text-align: center;

            border: 1px solid #d6d6d6;
            border-radius: 0.5rem;

            & > img {
                width: 4.6rem;
                margin-bottom: 4.2vh;
            }

            h6 {
                margin-bottom: 2.3vh;

                font-size: 1.25rem;
                font-weight: 700;
            }

            small {
                display: block;

                margin-bottom: 1rem;

                font-size: rem(13px);
                font-weight: 500;
            }

            .platforms {
                margin-bottom: 3vh;

                img {
                    width: 1.4rem;
                    margin: 0 0.7rem;
                }
            }
            .app-button {
                justify-content: center;

                width: 100%;
            }
        }
    }

    @include laptop {
        h2 {
            margin-bottom: 1rem;

            font-size: 2.75rem;
        }
        p {
            line-height: 1.5;
        }
        .list {
            .item {
                width: 16.3rem;
                margin: 0 0.6rem;
                padding: 1rem 2rem 1rem;

                & > img {
                    width: 4rem;
                    margin-bottom: 1rem;
                }

                h6 {
                    margin-bottom: 1rem;
                }
            }
        }
    }

    @include IE {
        display: block;
    }
}
</style>
