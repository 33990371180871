<template lang="pug">
    .page-404
        .number 404
        app-button(
            tag="router-link"
            :to="{ name: 'Home' }"
        ) На главную
</template>

<script>
export default {
    name: 'page-404',
};
</script>

<style scoped lang="scss">
.page-404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: calc(100vh - #{rem(51px)});

    .number {
        margin-bottom: rem(20px);

        font-size: rem(200px);
    }
}
</style>
