<template lang="pug">
    .transparent-header-layout
        layout-new-header(transparent)
        slot
        layout-new-footer

</template>

<script>
import LayoutNewFooter from '@/components/layout/NewFooter';
import LayoutNewHeader from '@/components/layout/NewHeader';

export default {
    name: 'transparent-header-layout',

    components: {
        LayoutNewFooter,
        LayoutNewHeader,
    },
};
</script>
