import { getOrder, initOrder, updateOrder } from '@/js/api/requests/order';

export default {
    namespaced: true,

    state: {
        order: null,
        selectedDealers: [],
    },

    mutations: {
        setOrder: (state, data) => {
            data?.items?.entries?.forEach((orderItem) => {
                orderItem.dealer = {
                    id: null,
                    name: null,
                };
            });

            state.order = data;
        },

        setOrderItems(state, data) {
            state.order.items.entries = data;
        },

        setDealerToOrder: (state, { targetOrderId, dealer }) => {
            if (targetOrderId) {
                const targetOrderItem = state.order?.items?.entries?.find(
                    (orderItem) => orderItem.id === targetOrderId,
                );

                if (targetOrderItem.dealer.id === dealer.id) return;

                if (targetOrderItem.dealer.id) {
                    state.selectedDealers = state.selectedDealers.filter(selectedDealer => selectedDealer !== targetOrderItem.dealer.id);
                    state.selectedDealers.unshift(dealer.id);

                    targetOrderItem.dealer.name = dealer.name;
                    targetOrderItem.dealer.id = dealer.id;

                    if (dealer.isAvailableForCity) {
                        targetOrderItem.dealer.isCity = dealer.isAvailableForCity;
                        targetOrderItem.dealer.isPro = false;
                    }

                    if (dealer.isAvailableForPro) {
                        targetOrderItem.dealer.isPro = dealer.isAvailableForPro;
                        targetOrderItem.dealer.isCity = false;
                    }

                    return;
                }
            }

            state.selectedDealers.unshift(dealer.id);

            const { isAvailableForCity: dealerForCity, isAvailableForPro: dealerForPro } = dealer;

            const filteredOrders = state.order?.items.entries.filter((i) => i.quantity > 0);

            const cityOrdersCount = filteredOrders.filter(
                (order) =>
                    order.group.product.isAvailableForCity &&
                    !order.group.product.isAvailableForPro,
            ).length;

            const proOrdersCount = filteredOrders.filter(
                (order) =>
                    order.group.product.isAvailableForPro &&
                    !order.group.product.isAvailableForCity,
            ).length;

            const isCityOrdersMore = cityOrdersCount > proOrdersCount;
            const isProOrdersMore = cityOrdersCount < proOrdersCount;
            const isCityAndProOrdersEqual = cityOrdersCount === proOrdersCount;

            if (dealerForPro && isProOrdersMore) {
                state.order?.items?.entries?.forEach((entree) => {
                    const product = entree.group.product;

                    const isAvailableForProAndCity =
                        product.isAvailableForPro && product.isAvailableForCity;

                    if (product.isAvailableForPro || isAvailableForProAndCity) {
                        entree.dealer.name = dealer.name;
                        entree.dealer.id = dealer.id;

                        if (dealer.isAvailableForCity) {
                            entree.dealer.isCity = dealer.isAvailableForCity;
                            entree.dealer.isPro = false;
                        }

                        if (dealer.isAvailableForPro) {
                            entree.dealer.isPro = dealer.isAvailableForPro;
                            entree.dealer.isCity = false;
                        }
                    }
                });
            }

            if ((dealerForPro && isCityOrdersMore) || (dealerForPro && isCityAndProOrdersEqual)) {
                state.order?.items?.entries?.forEach((entree) => {
                    const product = entree.group.product;
                    const isOrderOnlyPro = product.isAvailableForPro && !product.isAvailableForCity;
                    const isTargetProduct = entree.id === targetOrderId;

                    if (isOrderOnlyPro || isTargetProduct) {
                        entree.dealer.name = dealer.name;
                        entree.dealer.id = dealer.id;

                        if (dealer.isAvailableForCity) {
                            entree.dealer.isCity = dealer.isAvailableForCity;
                            entree.dealer.isPro = false;
                        }

                        if (dealer.isAvailableForPro) {
                            entree.dealer.isPro = dealer.isAvailableForPro;
                            entree.dealer.isCity = false;
                        }
                    }
                });
            }

            if ((dealerForCity && isCityOrdersMore) || (dealerForCity && isCityAndProOrdersEqual)) {
                state.order?.items?.entries?.forEach((entree) => {
                    const product = entree.group.product;

                    const isAvailableForProAndCity =
                        product.isAvailableForPro && product.isAvailableForCity;
                    const hasDealer = entree.dealer.id;

                    if (hasDealer) return;

                    if (product.isAvailableForCity || isAvailableForProAndCity) {
                        entree.dealer.name = dealer.name;
                        entree.dealer.id = dealer.id;

                        if (dealer.isAvailableForCity) {
                            entree.dealer.isCity = dealer.isAvailableForCity;
                            entree.dealer.isPro = false;
                        }

                        if (dealer.isAvailableForPro) {
                            entree.dealer.isPro = dealer.isAvailableForPro;
                            entree.dealer.isCity = false;
                        }
                    }
                });
            }

            if (dealerForCity && isProOrdersMore) {
                state.order?.items?.entries?.forEach((entree) => {
                    const product = entree.group.product;
                    const isOrderOnlyCity =
                        product.isAvailableForCity && !product.isAvailableForPro;

                    if (isOrderOnlyCity) {
                        entree.dealer.name = dealer.name;
                        entree.dealer.id = dealer.id;

                        if (dealer.isAvailableForCity) {
                            entree.dealer.isCity = dealer.isAvailableForCity;
                            entree.dealer.isPro = false;
                        }

                        if (dealer.isAvailableForPro) {
                            entree.dealer.isPro = dealer.isAvailableForPro;
                            entree.dealer.isCity = false;
                        }
                    }
                });
            }
        },
    },

    actions: {
        async getOrder({ commit }) {
            try {
                const orderId = localStorage.getItem('orderId');
                if (orderId) {
                    const data = await getOrder(orderId);
                    commit('setOrder', data);
                }
            } catch (e) {
                if (e.response.status === 404) {
                    localStorage.removeItem('orderId');
                }
            }
        },

        async initOrder({ commit }) {
            try {
                const data = await initOrder();
                localStorage.setItem('orderId', data.id);
                commit('setOrder', data);
            } catch (e) {
                console.log(e);
            }
        },

        async updateOrder({ state, commit, dispatch }, data) {
            if (!state.order) {
                await dispatch('initOrder');
            }

            try {
                const res = await updateOrder(state.order.id, data);
                commit('setOrder', {
                    ...state.order,
                    ...res,
                });
            } catch (e) {
                console.log(e);
            }
        },

        clearOrder({ commit }) {
            commit('setOrder', null);
            localStorage.removeItem('orderId');
        },
    },

    getters: {
        order: (state) => state.order,

        filteredOrderItems: (state) => state.order.items?.entries((i) => i.quantity > 0),

        getSelectedDealer: state => state.selectedDealers,
    },
};
