<template lang="pug">
    .bin-preview
        app-button.bin-button(@click="togglePopup")
            bin-icon
            span.forDesktop Корзина
            .count {{ orderItemsCount }}

        transition
            .bin-popup(v-show="isOpen")
                .top.forMobile_or_p
                    .bin-popup-counter
                        bin-icon
                        .count {{ orderItemsCount }}
                    h2 Корзина
                    app-button(@click="closePopup")
                        closer-icon
                    hr
                .products(
                    v-show="orderItemsCount > 0"
                    ref="products"
                )
                    div
                        .product(v-for="item in orderItems")
                            .product__preview
                                img(:src="getItemImage(item)")
                            .product__info
                                .product__data
                                    .product__name {{ item.title }}
                                    .product__code
                                        span Арт.:&nbsp;
                                        | {{ item.article }}
                                .product__divider.forDesktop
                                app-count-picker(
                                    :count="item.quantity"
                                    @minus="updateCountInOrder(item.variant.id, item.quantity - 1)"
                                    @plus="updateCountInOrder(item.variant.id, item.quantity + 1)"
                                )
                                .product__divider
                                .product__price {{ formattedCurrency(item.price * item.quantity) }}
                                    .product__price-currency &#8381

                .bottom(v-show="orderItemsCount > 0")
                    hr
                    .bottom__left
                        span Сумма заказа:
                        .bottom-total {{ formattedCurrency(orderPrice) }}
                            .bottom-total__currency &#8381
                    app-button(
                        tag="router-link"
                        :to="{ name: 'Order' }"
                    ) Оформить предзаказ

                .app-message(v-show="orderItemsCount === 0") Корзина пока пуста.
</template>

<script>
import formattedCurrency from '@/js/utils/formatted-currency';
import getImageUrl from '@/js/utils/get-image-url';
import binIcon from '@/assets/images/icons/bin.svg?inline';
import closerIcon from '@/assets/images/icons/closer.svg?inline';

import togglePopupMixin from '@/mixins/togglePopupMixin';

export default {
    name: 'bin-preview',

    components: {
        binIcon,
        closerIcon,
    },

    mixins: [togglePopupMixin],

    computed: {
        order() {
            return this.$store.getters['order/order'];
        },

        orderPrice() {
            return this.order?.totalPrice;
        },

        orderItems() {
            const items = this.order?.items.entries.filter(i => i.quantity > 0);
            items?.sort((i1, i2) => {
                return i1.id > i2.id ? 1 : i1.id < i2.id ? -1 : 0;
            });
            return items;
        },

        orderItemsCount() {
            return this.order?.totalQuantity || 0;
        },
    },

    mounted() {
        if (!window.isMobileOrTablet) {
            window.Scrollbar.init(this.$refs.products);
        }

        window.addEventListener('resize', this.resizeHandler);
    },

    beforeDestroy() {
        if (window.isMobileOrP) {
            window.enableScroll();
        }

        window.removeEventListener('resize', this.resizeHandler);
    },

    methods: {
        updateCountInOrder(id, count) {
            const fd = {
                variant: id,
                quantity: count,
            };
            this.$store.dispatch('order/updateOrder', fd);
        },

        getItemImage(item) {
            return getImageUrl(item.group.image);
        },

        clickOutsideHandler(e) {
            if (!e.target.closest('.bin-popup')) {
                this.closePopup();
            }
        },

        formattedCurrency,
    },
};
</script>

<style scoped lang="scss">
.bin-preview {
    position: relative;
}

.bin-button {
    position: relative;

    padding-right: rem(20px);
    padding-left: rem(10px);

    font-size: rem(12px);

    .count {
        position: absolute;
        top: rem(4px);
        left: rem(24px);

        padding: rem(2px) rem(3px) rem(1px);

        font-size: rem(8px);
        line-height: rem(10px);
        font-weight: 500;
        color: $black;

        background: #fff;
        border-radius: rem(10px);

        transition: inherit;
    }
}

.bin-popup {
    position: absolute;
    top: calc(100% + #{rem(16px)});
    right: 0;
    z-index: 1000;

    width: rem(641px);
    padding: rem(28px) rem(4px) rem(40px) rem(40px);

    font-size: rem(13px);

    background: #fff;
    box-shadow: 0 0 rem(16px) rgba($black, 0.1);

    .products {
        max-height: rem(300px);
        margin-bottom: rem(10px);

        &::v-deep .scrollbar {
            &-track{
                right: 0;

                width: rem(4px);

                background: rgba($gray-light, 0.2);
            }

            &-thumb{
                width: inherit;

                background: rgba($black, 0.75);
            }
        }
    }

    .product {
        display: flex;
        align-items: center;

        margin-bottom: rem(20px);

        &__preview {
            flex-shrink: 0;

            width: rem(90px);
            margin-right: rem(44px);

            img {
                width: 100%;
            }
        }

        &__info {
            display: flex;
            align-items: center;
        }

        &__data {
            width: rem(195px);
        }

        &__name {
            margin-bottom: rem(3px);

            line-height: rem(16px);
            font-weight: 500;
        }

        &__code {
            line-height: rem(16px);
            font-weight: 500;
            color: $gray;

            span {
                color: $gray-light;
            }
        }

        &__divider {
            flex-shrink: 0;

            width: 2px;
            height: rem(33px);
            margin: 0 rem(20px);

            background: url(../../assets/images/divider-vertical.svg) repeat-y 0 0 / 100% auto;
        }

        &__price {
            font-size: rem(16px);
            line-height: 1.25;
            font-weight: 500;
            white-space: nowrap;

            &-currency {
                display: inline;

                margin-left: 0.4em;

                font-size: 0.7em;
            }
        }
    }

    .bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        hr {
            margin-right: rem(36px);
            margin-bottom:  rem(46px);
        }

        &__left {
            margin-right: rem(32px);

            span {
                line-height: 1.5;
                color: $gray;
            }
        }

        &-total {
            font-size: rem(26px);
            line-height: 1.25;
            font-weight: 500;

             &__currency {
                 display: inline;

                 margin-left: 0.4em;

                 font-size: 0.7em;
             }
        }
    }
}

@include mobile_or_tablet {
    .bin-popup {
        .products {
            overflow: auto;
        }
    }
}

@include mobile_or_P {
    .bin-button {
        svg {
            margin-right: 0;
        }
    }

    .bin-popup {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: initial;
        padding: rem(13px) 0 rem(12px);
        overflow: auto;

        .top {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            width: 100%;
            padding: 0 rem(10px);

            .app-button {
                width: auto;
                margin: 0 0 0 auto;
                padding: 0 rem(8px);

                clip-path: none;

                svg {
                    margin-top: 0;
                    margin-right: 0;

                    transform: translateX(-1px);
                }
            }

            h2 {
                margin-left: rem(23px);
            }

            .bin-popup-counter {
                position: relative;

                width: rem(43px);
                height: rem(39px);
                margin-left: rem(21px);

                svg {
                    width: 100%;

                    fill: $black;
                }

                .count {
                    position: absolute;
                    top: rem(4px);
                    right: rem(2px);

                    padding: rem(3px) rem(6px) rem(2px) rem(7px);

                    font-size: rem(10px);
                    line-height: rem(12px);
                    font-weight: 500;
                    color: #fff;

                    background: $black;
                    border-radius: rem(10px);

                    transition: inherit;
                }
            }

            hr {
                margin-top: rem(30px);
            }
        }

        .products {
            max-height: initial;
            margin-bottom: 0;
            padding: rem(30px) rem(10px) 0;
        }

        .product {
            align-items: flex-start;

            margin-bottom: rem(48px);

            &__preview {
                width: rem(73px);
                margin-right: rem(14px);
            }

            &__info {
                flex-wrap: wrap;
            }

            &__data {
                width: 100%;
            }

            &__code {
                margin-bottom: rem(22px);
            }

            &__divider {
                height: rem(28px);
                margin: 0 0 0 rem(10px);
            }

            &__price {
                flex: 0 0 rem(110px);

                text-align: right;
            }

            .app-count-picker {
                &::v-deep {
                    .app-count-picker__button {
                        width: rem(22px);
                        height: rem(22px);
                    }

                    .app-count-picker__count {
                        width: rem(42px);

                        font-size: rem(16px);
                    }
                }
            }
        }

        .bottom {
            padding: 0 rem(10px);

            hr {
                margin-right: 0;
                margin-bottom:  rem(40px);
            }

            &__left {
                display: flex;
                align-items: center;

                margin-bottom: rem(24px);

                span {
                    max-width: rem(70px);
                    margin-right: rem(7px);

                    line-height: rem(16px);
                }
            }
        }
    }

    @include landscape {
        .bin-popup {
            .top {
                hr {
                    margin-top: rem(10px);
                }
            }

            .product {
                margin-bottom: rem(32px);

                &__info {
                    flex-wrap: initial;

                    width: 100%;
                }

                &__code {
                    margin-bottom: 0;
                }
            }

            .bottom {
                justify-content: space-between;

                &__left {
                    margin-bottom: 0;
                }

                hr {
                    margin-bottom: rem(10px);
                }

                .app-button {
                    margin: 0;
                }
            }
        }
    }
}
</style>
