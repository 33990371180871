<template lang="pug">
    .app-loader
        loader-icon
</template>

<script>
import LoaderIcon from '@/assets/images/loader.svg?inline';

export default {
    name: 'app-loader',

    components: { LoaderIcon },
};
</script>
