<template lang="pug">
    .order
        .order-container.container
            app-breadcrumbs(:links="breadcrumbsLinks")

            .top
                h2 Оформление предзаказа

            .content(v-if="orderItemsCount || success")
                pre-order(
                    @change-dealer="handleChangeDealer")
                form.form( @submit.prevent="submit")
                    .form__block
                        .form__title Контактные данные
                        br.forDesktop
                        .form__row
                            app-text-field(
                                v-model="firstName"
                                label="Имя*"
                                :error="firstNameError"
                                name="name"
                                autocomplete="on"
                            )
                            app-text-field(
                                v-model="lastName"
                                label="Фамилия*"
                                :error="lastNameError"
                                name="surname"
                                autocomplete="on"
                            )
                        .form__row
                            app-text-field(
                                v-model="phone"
                                label="Телефон*"
                                placeholder="+7 (___) ___-__-__"
                                v-mask="'+7 (###) ###-##-##'"
                                :error="phoneError"
                                name="phone"
                                autocomplete="on"
                            )
                            app-text-field(
                                v-model="email"
                                label="Email*"
                                :error="emailError"
                                name="email"
                                autocomplete="on"
                            )
                    .form__block
                        .form__title(ref="selectDealerBlock") Выбор дилерского центра
                        .form__subtitle Выберите дилерский центр, в котором вы хотели бы получить заказ
                        dealers-map(
                            v-model="dealer"
                            :selected-dealer="selectedDealer"
                            :error="dealerError"
                            @input="handleDealersInput"
                            @clearSelectedChangeDealer="clearSelectedChangeDealer"
                        )
                    .form__block
                        //.form__title.form__title--large Способ оплаты
                        //.form__subtitle.forMobile_or_p при получении
                        br
                        //.form__buttons
                        //    app-button(
                        //        tag="div"
                        //        :transparent="paymentMethod !== 'card'"
                        //        :class="{ active: paymentMethod === 'card' }"
                        //        @click="paymentMethod = 'card'"
                        //    )
                        //        pay-card-icon
                        //        span.forDesktop Картой при получении
                        //        span.forMobile_or_p Картой
                        //    app-button(
                        //        tag="div"
                        //        :transparent="paymentMethod !== 'cash'"
                        //        :class="{ active: paymentMethod === 'cash' }"
                        //        @click="paymentMethod = 'cash'"
                        //    )
                        //        pay-cash-icon
                        //        span.forDesktop Наличными при получении
                        //        span.forMobile_or_p Наличными
                        //br
                        //br
                        //br
                        app-checkbox(
                            v-model="pdnAgreement"
                            label="Я ознакомлен (-а) с <a href='https://haval.ru/user-agreement/' target='_blank'>Пользовательским соглашением</a>*"
                            :error="pdnAgreementError"
                        )
                        br
                        hr
                        br
                        app-checkbox(
                            v-model="communicationAgreement"
                            label="Я даю согласие на <a href='https://haval.ru/agree-of-personal-data/special-offer/' target='_blank'>обработку персональных данных</a>; <a href='https://haval.ru/agree-of-personal-data/' target='_blank'>предоставление персональных данных третьим лицам и коммуникацию</a>; ознакомлен (-а) с&nbsp;<a href='https://haval.ru/privacy-policy/' target='_blank'>Политикой конфиденциальности</a>*"
                            :error="communicationAgreementError"
                        )
                        br
                        br
                        br
                        app-button(:loading="loading") Оформить предзаказ


            template(v-else)
                .app-message Вы не выбрали ни одного товара
                br
                br
                app-button(
                    tag="router-link"
                    :to="{ name: 'Products' }"
                ) Перейти к аксессуарам
                br
                br
                br
                br

        order-success-popup(ref="successPopup")
</template>

<script>
import PreOrder from '@/components/pages/order/PreOrder';
import DealersMap from '@/components/pages/order/DealersMap';
import OrderSuccessPopup from '@/components/pages/order/SuccessPopup';

import payCardIcon from '@/assets/images/icons/pay-card.svg?inline';
import payCashIcon from '@/assets/images/icons/pay-cash.svg?inline';

import { required, email } from 'vuelidate/lib/validators';
import { checkoutOrder } from '@/js/api/requests/order';
import sendToDataLayer from '@/js/utils/sendToDataLayer';

export default {
    name: 'index',

    components: {
        PreOrder,
        DealersMap,
        OrderSuccessPopup,
        payCardIcon,
        payCashIcon,
    },

    data: () => ({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',

        paymentMethod: 'cash', // card, cash

        pdnAgreement: false,
        communicationAgreement: false,

        loading: false,
        success: false,

        dealer: null,
        selectedOrderItemForSetDealer: null,
        selectedDealer: null,
    }),

    validations: {
        firstName: { required },
        lastName: { required },
        phone: {
            required,
            format(value) {
                const phone = value.replace(/\D/g, '');
                return phone.length === 11 && phone.indexOf('79') === 0;
            },
        },
        email: { required, email },
        dealer: { required },
        pdnAgreement: {
            agree(value) {
                return value;
            },
        },
        communicationAgreement: {
            agree(value) {
                return value;
            },
        },
    },

    computed: {
        order() {
            return this.$store.getters['order/order'];
        },

        orderItemsCount() {
            const items = this.order?.items.entries.filter((i) => i.quantity > 0);
            return items?.length || 0;
        },

        firstNameError() {
            if (this.$v.$dirty && !this.$v.firstName.required) {
                return 'Поле обязательно для заполнения';
            }
            return '';
        },

        lastNameError() {
            if (this.$v.$dirty && !this.$v.lastName.required) {
                return 'Поле обязательно для заполнения';
            }
            return '';
        },

        phoneError() {
            if (this.$v.$dirty) {
                if (!this.$v.phone.required) {
                    return 'Поле обязательно для заполнения';
                } else if (!this.$v.phone.format) {
                    return 'Неверный формат';
                }
            }
            return '';
        },

        emailError() {
            if (this.$v.$dirty) {
                if (!this.$v.email.required) {
                    return 'Поле обязательно для заполнения';
                } else if (!this.$v.email.email) {
                    return 'Неверный формат';
                }
            }
            return '';
        },

        dealerError() {
            if (this.$v.$dirty && !this.$v.dealer.required) {
                return 'Выберите дилера';
            }
            return '';
        },

        pdnAgreementError() {
            return this.$v.$dirty && !this.$v.pdnAgreement.agree;
        },

        communicationAgreementError() {
            return this.$v.$dirty && !this.$v.communicationAgreement.agree;
        },

        breadcrumbsLinks() {
            return [
                {
                    to: { name: 'Home' },
                    name: 'Главная',
                },
                {
                    to: { name: 'Products' },
                    name: 'Аксессуары HAVAL',
                },
                {
                    name: 'Оформление предзаказа',
                },
            ];
        },
    },

    created() {
        sendToDataLayer({ event: 'go_to_cart' });
    },

    beforeDestroy() {
        this.$store.commit('setDealersByOrderItem', []);
    },

    methods: {
        async submit() {
            const orderItemDetails = this.getOrderItemDetails();

            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (this.loading) return;

            const fd = {
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone.replace(/\D/g, ''),
                email: this.email,
                payment: this.paymentMethod,
                subscribed: this.communicationAgreement,
                orderItemDetails,
            };

            this.loading = true;

            try {
                await checkoutOrder(this.order?.id, fd);
                this.success = true;
                const hasMultipleDealers = this.checkHasMultipleDealers();
                this.$store.dispatch('order/clearOrder');
                this.$refs.successPopup.open(hasMultipleDealers);
                sendToDataLayer({ event: 'submit_form' });
                sendToDataLayer({
                    event: 'form_success',
                    formName: 'haval_shop_order_form',
                });
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },

        clearForm() {
            this.firstName = this.lastName = this.phone = this.email = '';
            this.dealer = null;
            this.paymentMethod = 'cash';
            this.pdnAgreement = this.communicationAgreement = false;
            this.$v.$reset();
        },

        handleDealersInput(dealer) {
            if (this.selectedOrderItemForSetDealer) {
                const currentOrderInfo = this.order?.items.entries?.find(
                    (entry) => entry.id === this.selectedOrderItemForSetDealer,
                ).group.product;

                if (currentOrderInfo.isAvailableForCity && currentOrderInfo.isAvailableForPro) {
                    this.$store.commit('order/setDealerToOrder', {
                        targetOrderId: this.selectedOrderItemForSetDealer,
                        dealer,
                    });

                    return;
                }

                if (
                    (currentOrderInfo.isAvailableForCity && !dealer.isAvailableForCity) ||
                    (!currentOrderInfo.isAvailableForCity && dealer.isAvailableForCity)
                )
                    return;
            }

            this.$store.commit('order/setDealerToOrder', {
                targetOrderId: this.selectedOrderItemForSetDealer,
                dealer,
            });
        },

        checkDealersValidation(orderItemDetails) {
            const isValid = orderItemDetails.every(
                (orderItem) => orderItem.orderItem && orderItem.dealer,
            );

            return isValid;
        },
        clearSelectedChangeDealer() {
            this.selectedDealer = null;
        },
        handleChangeDealer({ orderItemId, dealerData }) {
            this.selectedOrderItemForSetDealer = orderItemId;

            this.selectedDealer = dealerData.id;

            const { selectDealerBlock } = this.$refs;

            if (selectDealerBlock) {
                this.$scrollTo(selectDealerBlock);
            }
        },

        getOrderItemDetails() {
            const entries = this.order?.items.entries.filter((i) => i.quantity > 0);

            const orderItemDetails = [];

            entries.forEach((entree) => {
                orderItemDetails.push({
                    orderItem: entree.id,
                    dealer: entree.dealer.id,
                });
            });

            return orderItemDetails;
        },

        checkHasMultipleDealers() {
            const entries = this.order?.items.entries.filter((i) => i.quantity > 0);

            const hasCityDealer = entries.some((entree) => entree.dealer.isCity);
            const hasProDealer = entries.some((entree) => entree.dealer.isPro);

            return hasCityDealer && hasProDealer;
        },
    },
};
</script>

<style scoped lang="scss">
.order {
    padding-bottom: rem(60px);

    .order-container {
        max-width: calc(#{rem(1393px)} + #{rem(16px)} * 2);
        margin: 0 auto;
    }

    .top {
        margin-bottom: rem(34px);
    }

    .content {
        display: flex;
        align-items: flex-start;
    }

    .form {
        width: 100%;
        padding-top: rem(16px);

        &__block {
            margin-bottom: rem(50px);
        }

        &__title {
            margin-bottom: rem(10px);

            font-size: rem(22px);
            line-height: rem(36px);
            font-weight: 500;
        }

        &__subtitle {
            margin-bottom: rem(54px);

            font-size: rem(13px);
            line-height: rem(16px);
            font-weight: 500;
            color: $gray;
        }

        &__row {
            display: flex;
            justify-content: space-between;

            margin-bottom: rem(25px);

            .app-text-field {
                width: calc((100% - #{rem(20px)}) / 2);
            }
        }

        &__buttons {
            display: flex;

            .app-button {
                font-size: rem(12px);

                clip-path: none;

                &:first-of-type {
                    margin-right: rem(18px);
                }

                &.active {
                    color: #fff;

                    background: $black;

                    pointer-events: none;

                    svg {
                        fill: #fff;
                        stroke: #fff;
                    }
                }
            }
        }
    }

    @include mobile_or_P {
        padding-bottom: rem(70px);

        .top {
            margin-bottom: rem(30px);

            h2 {
                margin-bottom: rem(34px);
            }
        }

        .content {
            flex-direction: column;
        }

        .form {
            max-width: 20rem;
            margin: 0 auto;

            &__block {
                margin-bottom: rem(16px);
            }

            &__title {
                margin-bottom: rem(16px);

                font-size: rem(20px);
                line-height: rem(24px);

                &--large {
                    font-size: rem(28px);
                    line-height: rem(34px);
                }
            }

            &__subtitle {
                margin: rem(-9px) 0 rem(18px);
            }

            &__row {
                flex-direction: column;

                margin-bottom: 0;

                .app-text-field {
                    width: 100%;
                    margin-bottom: rem(24px);
                }
            }

            &__buttons {
                .app-button {
                    flex-grow: 1;

                    width: initial;

                    font-size: rem(9px);

                    &:first-of-type {
                        margin-right: rem(12px);
                    }
                }
            }
        }

        @include landscape {
            .top {
                h2 {
                    text-align: center;
                }
            }
        }
    }
}
</style>
