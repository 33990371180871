<template lang="pug">
    transition
        .order-success-popup(
            v-if="isOpen"
            @click.self="close"
        )
            .content
                .title Спасибо!
                .subtitle за заявку
                hr
                .text Спасибо за заявку. Дилер свяжется с вами в ближайшее время.
                    template(v-if="hasMultipleDealers" )  Просим обратить внимание, что доставка товаров разных линеек осуществляется в разные дилерские центры.
                app-button(@click="close")
                    hand-icon
                    | Хорошо
                closer-icon.closer(@click="close")

</template>

<script>
import handIcon from '@/assets/images/icons/hand.svg?inline';
import closerIcon from '@/assets/images/icons/closer-cutted.svg?inline';

export default {
    name: 'order-success-popup',

    components: {
        handIcon,
        closerIcon,
    },

    data: () => ({
        isOpen: false,
        hasMultipleDealers: false,
    }),

    methods: {
        open(hasMultipleDealers = false) {
            this.hasMultipleDealers = hasMultipleDealers;

            this.isOpen = true;
            window.disableScroll();
        },

        close() {
            this.$router.push({ name: 'Products' });
            this.isOpen = false;
            window.enableScroll();
        },
    },
};
</script>

<style scoped lang="scss">
.order-success-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;

    background: rgba(#fff, 0.8);

    backdrop-filter: blur(8px);

    .content {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;

        width: rem(641px);
        padding: rem(29px) rem(40px) rem(41px);

        background: #fff;
        box-shadow: 0 0 rem(50px) rgba(#000, 0.1);
    }

    .title {
        margin-bottom: rem(3px);

        font-size: rem(34px);
        line-height: 1.1;
        font-weight: 500;
    }

    .subtitle {
        font-size: rem(24px);
        line-height: 1.2;
        font-weight: 500;
        text-align: center;
    }

    hr {
        margin: rem(40px) 0;
    }

    .text {
        margin-bottom: rem(44px);

        font-size: rem(14px);
        line-height: 1.1;
        text-align: center;
    }

    .closer {
        position: absolute;
        top: rem(14px);
        right: rem(15px);

        width: rem(12px);

        cursor: pointer;
        transition: box-shadow .2s;

        &:hover {
            box-shadow: 0 0 0 rem(6px) rgba($black, 0.05), inset 0 0 0 rem(10px) rgba($black, 0.05);
        }
    }

    @include mobile_or_P {
        .content {
            padding: rem(29px) rem(10px) rem(43px);
        }
        .title {
            font-size: rem(26px);
        }

        .subtitle {
            font-size: rem(18px);
        }

        hr {
            margin: rem(24px) 0;
        }

        .text {
            margin-bottom: rem(32px);

            font-size: rem(12px);
        }

        .app-button {
            width: auto;
        }

        @include landscape {
            .content {
                max-width: 100vmin;
                padding: rem(16px) rem(10px);
            }

            hr {
                margin: rem(16px) 0 rem(10px);
            }

            .text {
                margin-bottom: rem(20px);
            }
        }
    }
}
</style>
