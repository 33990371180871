import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import order from './order';
import favorites from './favorites';

import { isEmpty } from 'lodash';
import { getMenu, getDealers, getDealersByOrderItemId } from '@/js/api/requests/catalog';

function compareNumeric(a, b) {
    if (a > b) return 1;
    if (a === b) return 0;
    if (a < b) return -1;
}

export default new Vuex.Store({
    state: {
        menu: [],
        dealers: [],
        dealersByOrderItem: [],
    },

    mutations: {
        setMenu: (state, data) => {
            // const result = data?.reduce((item, acc) => {
            //     if (result) return acc;
            // }, []);
            state.menu = data;
        },

        setDealers: (state, data) => {
            state.dealers = data;
        },

        setDealersByOrderItem: (state, data) => {
            state.dealersByOrderItem = data;
        },
    },

    actions: {
        async getMenu({ state, commit }) {
            if (!isEmpty(state.menu)) return;

            try {
                const data = await getMenu();
                commit('setMenu', data.entries);
            } catch (error) {
                console.log(error);
            }
        },

        async getDealers({ state, commit }) {
            if (!isEmpty(state.dealers)) return;

            try {
                const data = await getDealers();
                commit('setDealers', data.entries);
            } catch (error) {
                console.log(error);
            }
        },

        async getDealersByOrderItemId({ commit }, orderItemId) {
            try {
                const data = await getDealersByOrderItemId(orderItemId);

                commit('setDealersByOrderItem', data.entries);
            } catch (error) {
                console.log(error);
            }
        },
    },

    getters: {
        menu: (state) => state.menu.sort((a, b) => compareNumeric(a.position, b.position)),
        dealers: (state) => state.dealers,
        dealersByOrderItem: (state) => state.dealersByOrderItem,
    },

    modules: {
        order,
        favorites,
    },
});
