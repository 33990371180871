<template lang="pug">
    .app-select(
        ref="select"
        :class="classes"
    )

        label {{ label }}

        Multiselect(
            v-model="state"
            :options="options"
            label="name"
            :preselectFirst="preselectFirst"
            :searchable="searchable"
            :allowEmpty="false"
            placeholder=""
            :disabled="disabled"
            @open="focusHandler"
        )
            span(slot="noResult") {{ noResultText }}

        .app-select__error(v-if="error" v-html="error")
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'app-select',

    components: {
        Multiselect,
    },

    props: {
        value: {
            validator(){
                return 1;
            },
            required: true,
        },

        options: {
            type: Array,
            required: true,
        },

        label: {
            type: String,
            required: false,
            default: '',
        },

        preselectFirst: {
            type: Boolean,
            required: false,
            default: false,
        },

        searchable: {
            type: Boolean,
            required: false,
            default: false,
        },

        noResultText: {
            type: String,
            default: 'Ничего не найдено',
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        error: {
            type: [String, Boolean],
            required: false,
            default: '',
        },
    },

    data: () => ({
        scrollIsInit: false,
    }),

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        classes() {
            return {
                'app-select--disabled': this.disabled,
                'app-select--invalid': this.error,
            };
        },
    },

    methods: {
        focusHandler() {
            setTimeout(() => {
                if (this.scrollIsInit) return;
                this.scrollIsInit = true;
                window.Scrollbar.init(this.$refs.select.querySelector(`.multiselect__content-wrapper`), {
                    alwaysShowTracks: true,
                });
            });
        },
    },
};
</script>
