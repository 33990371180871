import axios from 'axios';

const config = {
    baseURL: process.env.VUE_APP_API_PATH,
};

const httpClient = axios.create(config);

httpClient.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

httpClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error);
    },
);

/** Добавление токена аутентификации, и обновление его, если это требуется */
/*const authInterceptor = config => {
    // достаём токент аутентификации пользователя, с LocalStorage, или cookies, например
    const authToken = '...';
    config.headers.Authorization = `Bearer ${authToken}`;

    return config;
};*/

/** добавлени логгера при каждом axios запросе */
/*const loggerInterceptor = config => {
    /!** как-то обрабатываем логи *!/
    return config;
}*/

/** Добавляем экземпляру созданные обработчики для аутентификации и логов */
/*httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);*/

const getResource = async (url, data, method) => {
    let requestOmj;

    if (method === 'post') {
        requestOmj = { url, method, data };
    } else  {
        requestOmj = { url, method, params: data };
    }

    const response =  await httpClient(requestOmj);
    return response.data;
};

export const get = async (url, params) => getResource(url, params, 'get');
export const post = async (url, data) => getResource(url, data, 'post');
