export default {
    data: () => ({
        isOpen: false,
    }),

    methods: {
        togglePopup() {
            if (!this.isOpen) {
                this.isOpen = true;
                setTimeout(() => {
                    document.addEventListener('click', this.clickOutsideHandler);
                });
                if (window.isMobileOrP) {
                    window.disableScroll();
                }
            } else {
                this.closePopup();
            }
        },

        closePopup() {
            this.isOpen = false;
            document.removeEventListener('click', this.clickOutsideHandler);
            if (window.isMobileOrP) {
                window.enableScroll();
            }
        },

        resizeHandler() {
            if (this.isOpen) {
                if (window.isMobileOrP) {
                    window.disableScroll();
                } else {
                    window.enableScroll();
                }
            }
        },
    },
};
