<template lang="pug">
    app-button.favorites-button(
        tag="router-link"
        :to="{ name: 'Favorites' }"
    )
        heart-icon
        span.forDesktop Избранное
        .count {{ favoritesCount }}
</template>

<script>
import heartIcon from '@/assets/images/icons/heart.svg?inline';

export default {
    name: 'favorites-button',

    components: {
        heartIcon,
    },

    computed: {
        favoritesCount() {
            return this.$store.getters['favorites/favorites'].length;
        },
    },
};
</script>

<style scoped lang="scss">
.favorites-button {
    position: relative;

    padding-right: rem(20px);
    padding-left: rem(10px);

    font-size: rem(12px);

    .count {
        position: absolute;
        top: rem(4px);
        left: rem(24px);

        padding: rem(2px) rem(3px) rem(1px);

        font-size: rem(8px);
        line-height: rem(10px);
        font-weight: 500;
        color: $black;

        background: #fff;
        border-radius: rem(10px);

        transition: inherit;
    }

    @include mobile_or_P {
        width: auto;

        svg {
            margin-right: 0;
        }
    }
}
</style>
