<template lang="pug">
    .add-to-cart-button
        template(v-if="countInOrder > 0")
            app-button(disabled) Товар в&nbsp;корзине

            app-count-picker(
                :count="countInOrder"
                @minus="updateCountInOrder(countInOrder - 1)"
                @plus="updateCountInOrder(countInOrder + 1)"
            )

        app-button(
            v-else
            :disabled="disabled"
            @click="updateCountInOrder(1)"
        ) Добавить в&nbsp;корзину
</template>

<script>
import binChosenIcon from '@/assets/images/icons/bin-chosen.svg?inline';

export default {
    name: 'add-to-cart-button',

    components: {
        binChosenIcon,
    },

    props: {
        variant: {
            type: Object,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        order() {
            return this.$store.getters['order/order'];
        },

        countInOrder() {
            const product = this.order?.items.entries.find(o => o.variant.id === this.variant?.id);
            return product?.quantity;
        },
    },

    methods: {
        updateCountInOrder(count) {
            const fd = {
                variant: this.variant.id,
                quantity: count,
            };
            this.$store.dispatch('order/updateOrder', fd);
        },
    },
};
</script>

<style scoped lang="scss">
.add-to-cart-button {
    display: flex;
    align-items: center;

    .app-button {
        margin-right: rem(8px);
    }

    .app-count-picker {
        margin-right: rem(11px);
    }

    @include mobile_or_P {
        flex-wrap: wrap;

        margin-bottom: rem(14px);

        .app-button {
            width: rem(190px);
            margin-right: rem(8px);
            margin-left: 0;

            font-size: rem(8px);
        }

        .app-count-picker {
            margin-right: 0;
        }
    }
}
</style>
