<template lang="pug">
    .app-checkbox(:class="classes")
        input(
            :id="uid"
            v-model="state"
            type="checkbox"
        )
        label(:for="uid")
            span(v-html="label")
</template>

<script>
import { uniqueId } from 'lodash';

export default {
    name: 'app-checkbox',

    props: {
        value: {
            type: [String, Boolean],
            required: true,
        },

        label: {
            type: String,
            required: false,
            default: '',
        },

        error: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        uid: null,
    }),

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        classes() {
            return {
                'app-checkbox--invalid': this.error,
            };
        },
    },

    mounted() {
        this.uid = 'checkbox-' + uniqueId();
    },
};
</script>
