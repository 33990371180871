<template lang="pug">
    .sharing(ref="sharing")
        app-button.sharing-button(
            transparent
            @click="open"
        )
            sharing-icon

        transition
            .sharing-popup(
                v-if="isOpen"
                @click.self="close"
            )
                .content
                    .title Поделиться
                    .group
                        .group__title Месенджеры:
                        .group__list
                            //.group__item(@click="share('fb')")
                                fb-icon
                            .group__item(@click="share('tg')")
                                tg-icon
                            .group__item(@click="share('vk')")
                                vk-icon
                            .group__item(@click="share('whatsapp')")
                                whatsapp-icon
                            .group__item.viber-share(@click="share('viber')")
                                viber-icon
                            a.group__item.viber-link(href="https://www.viber.com/ru/?utm_source=Partner&utm_medium=Sharebutton&utm_campaign=Defualt" target="_blank")
                                viber-icon
                    hr.forDesktop
                    .group
                        .group__title Отправить на почту:
                        .group__list
                            a.group__item(:href="mailToLink")
                                mail-icon

                    app-button.closer(
                        transparent
                        @click="close"
                    )
                        closer-icon

        share-network.vk(
            network="vk"
            :url="url"
            :title="title"
            :media="image"
        )
        //share-network.fb(
            network="facebook"
            //:url="url"
            //:title="title"
        //)
        share-network.tg(
            network="telegram"
            :url="url"
            :title="title"
        )
        share-network.whatsapp(
            network="whatsapp"
            :url="url"
            :title="title"
        )
        share-network.viber(
            network="viber"
            :url="url"
            :title="title"
        )
</template>

<script>
import sharingIcon from '@/assets/images/icons/sharing.svg?inline';
import closerIcon from '@/assets/images/icons/closer.svg?inline';

import fbIcon from '@/assets/images/icons/sharing/fb.svg?inline';
import tgIcon from '@/assets/images/icons/sharing/tg.svg?inline';
import vkIcon from '@/assets/images/icons/sharing/vk.svg?inline';
import whatsappIcon from '@/assets/images/icons/sharing/whatsapp.svg?inline';
import viberIcon from '@/assets/images/icons/sharing/viber.svg?inline';
import mailIcon from '@/assets/images/icons/sharing/mail.svg?inline';

const sharingSizes = {
    vk: {
        width: 1024,
        height: 456,
    },
    default: {
        width: 1200,
        height: 630,
    },
};

export default {
    name: 'sharing',

    components: {
        sharingIcon,
        closerIcon,

        fbIcon,
        tgIcon,
        vkIcon,
        whatsappIcon,
        viberIcon,
        mailIcon,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },

        noDisableScroll: {
            type: Boolean,
            required: false,
        },
    },

    data: () => ({
        sharedSoc: null,
        isOpen: false,
    }),

    computed: {
        url() {
            return `${process.env.VUE_APP_URL}/share.php?image=${this.image}&title=${this.title}&redirectUrl=${this.redirectUrl}`;
        },

        redirectUrl() {
            return `${process.env.VUE_APP_URL}${
                this.$router.resolve({
                    name: 'ProductsDetail',
                    params: { slug: this.product.product.slug },
                    query: { group: this.product.color.slug },
                }).href
            }`;
        },

        title() {
            return this.product.product.title;
        },

        image() {
            const sizes = sharingSizes[this.sharedSoc] || sharingSizes.default;
            const config = {
                type: 'fit',
                width: sizes.width,
                height: sizes.height,
                density: 1,
                quality: 100,
                path: this.product.image,
            };
            const format = this.product.image.match(/\..+$/)[0];
            return (
                process.env.VUE_APP_BACKEND_PATH + '/media/' + btoa(JSON.stringify(config)) + format
            );
        },

        mailToLink() {
            return `mailto:?Subject=${this.title}&Body=${this.redirectUrl}`;
        },
    },

    methods: {
        share(soc) {
            this.sharedSoc = soc;
            setTimeout(() => {
                this.$refs.sharing.querySelector(`.${soc}`).click();
            });
        },

        open() {
            this.isOpen = true;
            if (!this.noDisableScroll) {
                window.disableScroll();
            }
        },

        close() {
            this.isOpen = false;
            if (!this.noDisableScroll) {
                window.enableScroll();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.sharing-button {
    padding: 0 rem(12px);

    border: none;

    svg {
        margin-top: 0;
        margin-right: rem(-4px);

        @include mobile_or_P {
            width: rem(21px);
        }
    }

    @media (hover: hover) {
        &:hover {
            background: none;

            svg {
                fill: $blue;
            }
        }
    }
}

.sharing-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;

    background: rgba(#fff, 0.8);

    backdrop-filter: blur(8px);

    .content {
        position: relative;

        width: rem(912px);
        padding: rem(53px) rem(75px) rem(61px);

        background: #fff;
        box-shadow: 0 0 rem(50px) rgba(#000, 0.1);
    }

    .title {
        margin-bottom: rem(32px);

        font-size: rem(24px);
        font-weight: 500;
    }

    .group {
        display: flex;
        align-items: center;

        &__title {
            width: rem(220px);

            font-size: rem(13px);
            font-weight: 500;
        }

        &__list {
            display: flex;
        }

        &__item {
            width: rem(48px);
            margin-right: rem(38px);

            cursor: pointer;

            svg {
                width: 100%;

                path {
                    transition: fill 0.2s;
                }
            }

            @media (hover: hover) {
                &:hover path {
                    fill: $blue;
                }
            }

            &.viber-share {
                display: none;
            }
        }
    }

    hr {
        width: rem(562px);
        margin: rem(40px) 0;
    }

    .closer {
        position: absolute;
        top: rem(40px);
        right: rem(40px);

        width: auto;
        padding: 0 rem(8px);

        clip-path: none;

        svg {
            margin: 0;

            transform: translateX(-1px);
        }
    }

    @include mobile_or_tablet {
        .group__item {
            &.viber-link {
                display: none;
            }

            &.viber-share {
                display: block;
            }
        }
    }

    @include mobile_or_P {
        .content {
            width: rem(275px);
            padding: rem(19px) rem(20px) rem(23px);
        }

        .title {
            margin-bottom: rem(26px);
        }

        .group {
            flex-direction: column;
            align-items: flex-start;

            &:not(:last-of-type) {
                margin-bottom: rem(24px);
            }

            &__title {
                margin-bottom: rem(9px);
            }

            &__item {
                width: rem(32px);
                margin-right: rem(10px);
            }
        }

        .closer {
            top: rem(8px);
            right: rem(8px);

            border: none;
        }
    }
}
</style>
