import { get, post } from '@/js/api/http-helper';

export function initOrder() {
    return get('/order/init');
}

export function getOrder(id) {
    return get(`/order/${id}`);
}

export function updateOrder(id, data) {
    return post(`/order/${id}/item/update`, data);
}

export function checkoutOrder(id, data) {
    return post(`/order/${id}/checkout`, data);
}

