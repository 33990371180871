<template lang="pug">
    transition
        .fast-view-popup(
            v-if="isOpen"
            @click.self="close"
        )
            .content
                router-link.product__name.forMobile_or_p(
                    :to="productLink"
                    @click.native="close"
                ) {{ productName }}
                router-link.product__code.forMobile_or_p(
                    v-if="activeVariant"
                    :to="productLink"
                    @click.native="close"
                )
                    span Арт.:&nbsp;
                    | {{ activeVariantCode }}
                hr.forMobile_or_p
                product-slider(
                    v-if="activeGroup"
                    v-model="activeGroup"
                    :productGroups="productGroups"
                    @input="setActiveVariant"
                )

                .product__info
                    router-link.product__name.forDesktop(
                        :to="productLink"
                        @click.native="close"
                    ) {{ productName }}
                    router-link.product__code.forDesktop(
                        v-if="activeVariant"
                        :to="productLink"
                        @click.native="close"
                    )
                        span Арт.:&nbsp;
                        | {{ activeVariantCode }}

                    .product-sizes
                        .product-sizes__item(
                            v-for="variant in availableVariants"
                            :class="{ active: activeVariant === variant, sold: variant.sold }"
                            @click="activeVariant = variant"
                        ) {{ variant.size.name }}

                    .product__material(v-html="productMaterial")

                    hr

                    .product-price {{ formattedCurrency(productPrice) }}
                        .product-price__currency &#8381

                    .product-bottom
                        add-to-cart-button(
                            :variant="activeVariant"
                            :disabled="!activeVariant"
                        )
                        sharing(
                            :product="activeGroup"
                            no-disable-scroll
                        )
                        .product-bottom__divider.forDesktop
                        favorites-toggler(
                            :product-id="activeGroup.id"
                        )
                        router-link.product-detail-link(:to="productLink" @click.native="close") На страницу товара

                app-button.closer(
                    transparent
                    @click="close"
                )
                    closer-icon

</template>

<script>
import Sharing from '@/components/pages/Sharing';
import FavoritesToggler from '@/components/pages/FavoritesToggler';
import AddToCartButton from '@/components/pages/products/AddToCartButton';

import binAddIcon from '@/assets/images/icons/bin-add.svg?inline';
import closerIcon from '@/assets/images/icons/closer.svg?inline';

import formattedCurrency from '@/js/utils/formatted-currency';
import ProductSlider from '@/components/pages/products/ProductSlider';

export default {
    name: 'fast-view-popup',

    components: {
        ProductSlider,
        Sharing,
        AddToCartButton,
        FavoritesToggler,
        binAddIcon,
        closerIcon,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },

        productGroups: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        isOpen: false,
        activeGroup: null,
        activeVariant: null,
    }),

    computed: {
        productName() {
            return this.product.product.title;
        },

        activeVariantCode() {
            return this.activeVariant?.article;
        },

        productPrice() {
            return this.product.product.price;
        },

        productMaterial() {
            return this.product.product.material;
        },

        productMedia() {
            return this.product.media.entries;
        },

        availableVariants() {
            if (this.activeGroup) {
                const variants = [...this.activeGroup.variants.entries];
                variants.sort((v1,v2) => v1.size.sortOrder - v2.size.sortOrder);
                return variants;
            }
            return [];
        },

        productLink() {
            return {
                name: 'ProductsDetail',
                params: { slug: this.activeGroup?.product.slug },
                query: { group: this.activeGroup?.color.slug },
            };
        },
    },

    methods: {
        setActiveVariant() {
            this.activeVariant = this.availableVariants.find(v => !v.sold);
        },

        open() {
            this.isOpen = true;
            window.disableScroll();
            this.activeGroup = this.productGroups.find(g => g.color.slug === this.product.color.slug);
            this.setActiveVariant();
        },

        close() {
            this.isOpen = false;
            window.enableScroll();
        },

        formattedCurrency,
    },
};
</script>

<style scoped lang="scss">
.fast-view-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;

    background: rgba(#fff, 0.8);

    backdrop-filter: blur(8px);

    .content {
        position: relative;

        display: flex;

        width: rem(912px);
        padding: rem(42px) rem(26px) rem(30px) rem(62px);

        background: #fff;
        box-shadow: 0 0 rem(50px) rgba(#000, 0.1);
    }

    .product-slider {
        flex-shrink: 0;

        width: rem(310px);
        height: rem(300px);
        margin-right: rem(80px);

        &::v-deep {
            .swiper-container {
                margin-bottom: rem(10px);
            }

            .product-slider-groups__item {
                margin: 0 rem(16px);
            }

            .swiper-button-prev {
                left: rem(-32px);
            }

            .swiper-button-next {
                right: rem(-32px);
            }
        }
    }

    .product__info {
        width: 100%;
    }

    .product__name {
        display: block;

        margin-right: rem(58px);
        margin-bottom: rem(3px);

        font-size: rem(18px);
        line-height: 1.2;
        font-weight: 500;
    }

    .product__code {
        display: inline-block;

        font-size: rem(13px);
        line-height: 1.2;
        font-weight: 500;
        color: $gray;

        span {
            color: $gray-light;
        }
    }

    .product-sizes {
        margin: rem(30px) 0 rem(10px);

        &__item {
            position: relative;

            display: inline-block;

            margin: 0 rem(28px) rem(8px) 0;

            font-size: rem(14px);

            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                right: 0;
                bottom: rem(-3px);
                left: 0;

                display: block;

                height: 1px;

                background: repeating-linear-gradient(to right, $black 0, $black 4px, transparent 4px, transparent 8px);

                transition: opacity .2s;
            }

            &.active {
                font-weight: 500;

                &:after {
                    opacity: 0;
                }
            }

            &.sold {
                color: #BCBCBC;

                pointer-events: none;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    width: rem(32px);
                    height: 1px;

                    background-color: #BCBCBC;

                    transform: translate(-50%, -50%) rotate(-40deg);
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    .product__material {
        font-size: rem(12px);
        line-height: 125%;
    }

    hr {
        margin: rem(21px) 0 rem(6px);
    }

    .product-price {
        margin-bottom: rem(18px);

        font-size: rem(30px);
        line-height: 1.25;
        font-weight: 500;
        white-space: nowrap;

        &__currency {
            display: inline;

            margin-left: 0.4em;

            font-size: 0.7em;
        }
    }

    .product-bottom {
        display: flex;
        flex-wrap: wrap;

        .app-button {
            margin-right: rem(11px);
            margin-bottom: rem(26px);
        }

        &__divider {
            width: 2px;
            height: rem(36px);

            background: url(../../../assets/images/divider-vertical.svg) repeat-y 0 0 / 100% auto;
        }

        .product-detail-link {
            display: flex;
            align-items: center;

            margin-top: rem(24px);

            font-size: rem(13px);
            color: $gray-light;

            transition: color .2s;

            svg {
                width: rem(27px);
                margin-right: rem(4px);

                transition: fill .2s;

                fill: $gray-light;
            }

            @media (hover: hover) {
                &:hover {
                    color: $gray;

                    svg {
                        fill: $gray;
                    }
                }
            }
        }
    }

    .sharing::v-deep {
        .sharing-popup {
            position: absolute;

            width: 100%;

            .content {
                width: 100%;
                height: 100%;

                box-shadow: none;
            }
        }
    }

    .closer {
        position: absolute;
        top: rem(40px);
        right: rem(40px);

        width: auto;
        padding: 0 rem(8px);

        clip-path: none;

        svg {
            margin: 0;

            transform: translateX(-1px);
        }
    }

    @include mobile_or_P {
        align-items: flex-start;

        overflow: auto;

        background: #fff;

        .content {
            flex-direction: column;

            width: 100%;
            padding: rem(23px) rem(10px) rem(24px);

            box-shadow: none;
        }

        .product-slider {
            width: 100%;
            max-width: 20rem;
            margin: 0 auto rem(36px);

            &::v-deep {
                .swiper-button-prev {
                    left: 0;
                }

                .swiper-button-next {
                    right: 0;
                }
            }
        }

        .product__name {
            max-width: calc(100% - #{rem(60px)});
            margin-right: 0;
            margin-bottom: rem(7px);
        }

        hr {
            margin-bottom: rem(28px);
        }

        .product-price {
            max-width: 20rem;
            margin-right: auto;
            margin-left: auto;
            padding: 0 rem(12px);
        }

        .product-bottom {
            max-width: 20rem;
            margin: 0 auto;

            .sharing {
                margin-bottom: rem(14px);
            }

            .product-detail-link {
                order: 5;

                width: 100%;
                margin-top: rem(8px);
            }

            .favorites-toggler {
                order: 4;
            }
        }

        .sharing::v-deep {
            .sharing-popup {
                align-items: flex-end;

                padding-bottom: rem(75px);

                .content {
                    width: rem(290px);
                    height: auto;

                    box-shadow: 0 0 rem(50px) rgba(#000, 0.1);
                }

                .closer {
                    top: initial;
                    right: rem(4px);
                    bottom: rem(16px);
                }
            }
        }

        .closer {
            top: rem(10px);
            right: rem(10px);
        }
    }
}
</style>
