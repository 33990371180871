<template lang="pug">
    transition
        .determine-size-popup(
            v-if="isOpen"
            @click.self="close"
        )
            .content
                .title Таблица размеров. {{currentSizes.name}}
                .sizes
                    .table
                        .tr(v-for="(row, rowIndex) in currentSizes.table")
                            div(
                                v-for="(col, colIndex) in row"
                                :class="rowIndex === 0 || colIndex === 0 ? 'th' : 'td'"
                            ) {{ col }}
                    .image
                        img(:src="currentSizes.image")

                app-button.closer(
                    transparent
                    @click="close"
                )
                    closer-icon
</template>

<script>
import closerIcon from '@/assets/images/icons/closer.svg?inline';

export default {
    name: 'determine-size-popup',

    components: {
        closerIcon,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        isOpen: false,
        sizes: {
            switshot: {
                name: 'Свитшот',
                table: [
                    ['', 'S', 'M', 'L', 'XL', 'XXL'],
                    ['A', '60', '61.5', '63', '64.5', '66'],
                    ['B', '48', '50', '52', '54', '56'],
                    ['C', '53', '55.5', '58', '60.5', '63'],
                    ['D', '66', '68', '70', '72', '74'],
                ],
                image: require('@/assets/images/sizes-switshot.png'),
            },
            futbolka: {
                name: 'Футболка',
                table: [
                    ['', 'S', 'M', 'L', 'XL', 'XXL'],
                    ['A', '73', '73', '75', '75', '77'],
                    ['B', '53', '55', '57', '59', '63'],
                    ['C', '25', '25', '26', '26', '27'],
                ],
                image: require('@/assets/images/sizes-futbolka.png'),
            },
        },
    }),

    computed: {
        currentSizes() {
            if (this.product.title.toLowerCase().includes('толстовка') || this.product.title.toLowerCase().includes('свитшот')) {
                return this.sizes.switshot;
            }
            if (this.product.title.toLowerCase().includes('футболка')) {
                return this.sizes.futbolka;
            }
            return this.sizes.futbolka;
        },
    },

    methods: {
        open() {
            this.isOpen = true;
            window.disableScroll();
        },

        close() {
            this.isOpen = false;
            window.enableScroll();
        },
    },
};
</script>

<style scoped lang="scss">
.determine-size-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;

    background: rgba(#fff, 0.8);

    backdrop-filter: blur(8px);

    .content {
        position: relative;

        width: rem(912px);
        padding: rem(53px) rem(68px) rem(34px);

        background: #fff;
        box-shadow: 0 0 rem(50px) rgba(#000, 0.1);
    }

    .title {
        margin-bottom: rem(14px);

        font-size: rem(24px);
        line-height: rem(29px);
        font-weight: 700;
    }

    .sizes {
        display: flex;
    }

    .table {
        margin-right: rem(74px);

        .tr {
            display: flex;

            &:not(:last-child) {
                background: url(../../assets/images/divider.svg) repeat-x 0 bottom / auto 2px;
            }
        }

        .th, .td {
            display: flex;
            align-items: center;

            width: rem(60px);
            height: rem(62px);

            font-size: rem(18px);

            &:last-child {
                width: initial;
            }
        }

        .th {
            font-weight: 700;
        }

        .td {
            color: $gray;
        }
    }

    .image {
        width: rem(320px);
        margin-top: rem(-15px);

        img {
            width: 100%;
        }
    }


    .closer {
        position: absolute;
        top: rem(40px);
        right: rem(40px);

        width: auto;

        svg {
            margin: 0;

            transform: translateX(-1px);
        }
    }

    @include mobile_or_P {
        align-items: flex-start;

        overflow: auto;

        background: #fff;

        .content {
            width: 100%;
            padding: rem(23px) rem(10px) rem(24px);

            box-shadow: none;
        }

        .sizes {
            flex-direction: column-reverse;
        }

        .table {
            width: 100%;
            max-width: 20rem;
            margin: 0 auto;

            .th, .td {
                width: 17.5%;
            }
        }

        .image {
            width: 100%;
            max-width: 20rem;
            margin: 0 auto rem(10px);
        }

        .closer {
            top: rem(10px);
            right: rem(10px);
        }
    }
}
</style>
