<template lang="pug">
    .products-detail
        .container
            app-breadcrumbs(:links="breadcrumbsLinks")

            .top(v-if="product")
                h2 {{ productName }}
                .code(v-if="activeVariant")
                    span Арт.:&nbsp;
                    | {{ activeVariantCode }}
                favorites-button
                bin-preview
                hr.forMobile_or_p
                .product-tags
                    .product-tags__item(v-if="isProductFeatured") Скоро в продаже
                    .product-tags__item.blue(v-if="isProductSold") Распродано

            .wrapper
                product-slider(
                    v-if="activeGroup"
                    v-model="activeGroup"
                    :productGroups="productGroups"
                    @input="setActiveVariant"
                )

                .product-info
                    .price(v-if="productPrice") {{ formattedCurrency(productPrice) }}
                        .price__currency &#8381;

                    .buttons
                        add-to-cart-button(
                            :variant="activeVariant"
                            :disabled="!activeVariant"
                        )
                        sharing(
                            v-if="activeGroup"
                            :product="activeGroup"
                        )
                        .divider.forDesktop
                        favorites-toggler(
                            v-if="activeGroup"
                            :product-id="activeGroup.id"
                        )
                    hr
                    .tabs
                        .tabs__item
                            a(
                                :class="{active: activeTab === 'characteristics'}"
                                @click="activeTab = 'characteristics'"
                            )  Характеристики
                        .tabs__divider
                        .tabs__item
                            a(
                                :class="{active: activeTab === 'description'}"
                                @click="activeTab = 'description'"
                            )  Описание
                    .characteristics(v-if="activeTab === 'characteristics'")
                        .characteristics-item
                            .characteristics-item__name Материал:
                            .characteristics-item__text(v-html="productMaterial")
                        hr
                        .characteristics-item
                            .characteristics-item__name Размер:
                            .characteristics-item__text
                                .characteristics-item__size(
                                    v-for="variant in availableVariants"
                                    :class="{ active: activeVariant === variant, sold: variant.sold }"
                                    @click="activeVariant = variant"
                                ) {{ variant.size.name }}

                                .characteristics-item__size.determine-size.forMobile_or_p(
                                    v-if="canDetermineSize"
                                    @click="openDetermineSizePopup"
                                ) Определить свой размер

                            template(v-if="canDetermineSize")
                                .determine-divider.forDesktop
                                .characteristics-item__size.determine-size.forDesktop(@click="openDetermineSizePopup") Определить свой размер

                        hr
                        .characteristics-item
                            .characteristics-item__name Цвет:
                            .characteristics-item__text {{ activeColorName }}
                    .description(
                        v-else-if="activeTab === 'description'"
                        v-html="productDescription"
                    )

        div
        .disc {{productDisclaimer}}
        determine-size-popup(
            v-if="canDetermineSize"
            :product="product"
            ref="determineSizePopup"
        )
</template>

<script>
import FavoritesButton from '@/components/pages/FavoritesButton';
import BinPreview from '@/components/pages/BinPreview';
import Sharing from '@/components/pages/Sharing';
import FavoritesToggler from '@/components/pages/FavoritesToggler';
import DetermineSizePopup from '@/components/pages/DetermineSizePopup';
import formattedCurrency from '@/js/utils/formatted-currency';
import { getProductBySlug } from '@/js/api/requests/catalog';
import sendToDataLayer from '@/js/utils/sendToDataLayer';
import ProductSlider from '@/components/pages/products/ProductSlider';
import AddToCartButton from '@/components/pages/products/AddToCartButton';

export default {
    name: 'products-detail',

    components: {
        AddToCartButton,
        ProductSlider,
        FavoritesButton,
        BinPreview,
        Sharing,
        FavoritesToggler,
        DetermineSizePopup,
    },

    data: () => ({
        product: null,

        activeGroup: null,
        activeVariant: null,

        activeTab: 'characteristics', // characteristics, description
    }),

    computed: {
        productName() {
            return this.product?.title;
        },

        activeVariantCode() {
            return this.activeVariant?.article;
        },

        isProductFeatured() {
            return this.activeVariant?.featured;
        },

        isProductSold() {
            return !this.activeVariant;
        },

        productMaterial() {
            return this.product?.material;
        },

        productDescription() {
            return this.product?.description;
        },

        productPrice() {
            return this.product?.price;
        },

        productDisclaimer() {
            return this.product?.disclaimer;
        },

        productGroups() {
            return this.product?.groups.entries || [];
        },

        activeColorName() {
            return this.activeGroup?.color.name;
        },

        availableVariants() {
            if (this.activeGroup) {
                const variants = [...this.activeGroup.variants.entries];
                variants.sort((v1, v2) => v1.size.sortOrder - v2.size.sortOrder);
                return variants;
            }
            return [];
        },

        canDetermineSize() {
            return (
                this.product?.category.name === 'Толстовки и футболки' ||
                this.product?.category.name === 'Свитшоты и футболки'
            );
        },

        breadcrumbsLinks() {
            return [
                {
                    to: { name: 'Home' },
                    name: 'Главная',
                },
                {
                    to: { name: 'Products' },
                    name: 'Аксессуары HAVAL',
                },
                {
                    to: { name: 'Products', query: { category: this.product?.category.slug } },
                    name: this.product?.category.name,
                },
                {
                    name: this.productName,
                },
            ];
        },
    },

    async mounted() {
        await this.getProduct();
        const group = this.productGroups.find(g => g.color.slug === this.$route.query.group);
        if (group) {
            this.activeGroup = group;
        } else {
            this.activeGroup = this.productGroups[0];
        }
        this.setActiveVariant();

        sendToDataLayer({ event: 'view_product' });
    },

    methods: {
        setActiveVariant() {
            this.activeVariant = this.availableVariants.find(v => !v.sold);
        },

        async getProduct() {
            try {
                const slug = this.$route.params.slug;
                this.product = await getProductBySlug(slug);
            } catch (e) {
                if (e.response.status === 404) {
                    this.$router.push({ name: '404' });
                }
            }
        },

        openDetermineSizePopup() {
            this.$refs.determineSizePopup.open();
        },

        formattedCurrency,
    },
};
</script>

<style scoped lang="scss">
.products-detail {
    padding-bottom: rem(112px);
    .disc{
        width: 90%;
        margin: 30px auto;

        font-size: 10px;
        line-height: 12px;
        color: #444;

    }
    .app-breadcrumbs {
        margin-bottom: rem(35px);
    }

    .top {
        position: relative;


        display: flex;

        margin-bottom: rem(84px);

        h2 {
            align-self: center;

            font-size: rem(24px);
        }

        .code {
            display: flex;
            align-items: center;

            margin-right: rem(40px);
            margin-left: rem(41px);

            font-size: rem(13px);
            font-weight: 500;
            color: $gray;

            span {
                color: $gray-light;
            }

            &:before {
                content: '';

                display: block;

                width: 2px;
                height: rem(42px);
                margin-right: rem(38px);

                background: url(../../../assets/images/divider-vertical.svg) repeat-y 0 0 / 100%
                    auto;
            }
        }

        .favorites-button,
        .bin-preview {
            margin-top: rem(-72px);
        }

        .favorites-button {
            margin-right: rem(12px);
            margin-left: auto;
        }

        .product-tags {
            position: absolute;
            top: calc(100% + #{rem(6px)});
            left: 0;

            &__item {
                display: inline-flex;
                align-items: center;

                height: rem(33px);
                margin-right: rem(8px);
                margin-bottom: rem(8px);
                padding: 0 rem(18px) 0 rem(13px);

                font-size: rem(12px);
                font-weight: 500;
                text-transform: uppercase;

                background: #c4c4c4;

                clip-path: polygon(0 0, 100% 0, calc(100% - #{rem(10px)}) 100%, 0 100%);

                &.blue {
                    color: #fff;

                    background: #52595D;
                }
            }
        }
    }

    .wrapper {
        display: flex;
        align-items: flex-start;
    }

    .product-slider {
        flex-shrink: 0;

        margin-right: rem(147px);
    }

    .product-info {
        width: 100%;
        padding-top: rem(9px);
    }

    .tabs {
        display: flex;
        align-items: center;

        margin: rem(25px) 0 rem(55px);

        &__item {
            a {
                position: relative;

                font-size: rem(13px);
                line-height: 2;
                color: $gray;

                cursor: pointer;

                &:after {
                    content: '';
                    position: absolute;
                    top: calc(100% + #{rem(8px)});
                    right: 0;
                    left: 0;

                    display: block;

                    height: rem(8px);

                    background: url(../../../assets/images/tab-underline.svg) no-repeat center
                        center / auto 100%;

                    opacity: 0;
                    transition: opacity 0.2s;

                    pointer-events: none;
                }

                &.active {
                    font-weight: 500;
                    color: $black;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        &__divider {
            width: 2px;
            height: rem(42px);
            margin: 0 rem(40px) 0 rem(50px);

            background: url(../../../assets/images/divider-vertical.svg) repeat-y 0 0 / 100% auto;
        }
    }

    .characteristics,
    .description {
        margin-bottom: rem(36px);
    }

    .characteristics {
        &-item {
            display: flex;

            font-size: rem(13px);
            line-height: 1.2;

            &__name {
                flex-shrink: 0;

                width: rem(120px);

                color: $gray-light;
            }

            &__size {
                position: relative;

                display: inline-block;

                margin: 0 rem(28px) rem(8px) 0;

                cursor: pointer;

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: rem(-3px);
                    left: 0;

                    display: block;

                    height: 1px;

                    background: repeating-linear-gradient(
                        to right,
                        $black 0,
                        $black 4px,
                        transparent 4px,
                        transparent 8px
                    );

                    transition: opacity 0.2s;
                }

                &.active {
                    font-weight: 500;

                    &:after {
                        opacity: 0;
                    }
                }

                &.sold {
                    color: #BCBCBC;

                    pointer-events: none;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;

                        width: rem(32px);
                        height: 1px;

                        background-color: #BCBCBC;

                        transform: translate(-50%, -50%) rotate(-40deg);
                    }

                    &::after {
                        display: none;
                    }
                }
            }

            .determine-divider {
                flex-shrink: 0;

                width: 2px;
                margin-right: rem(26px);

                background: url(../../../assets/images/divider-vertical.svg) repeat-y 0 0 / 100%
                    auto;
            }

            .determine-size {
                align-self: flex-start;

                margin-right: 0;

                color: $gray;
                white-space: nowrap;

                transition: opacity 0.2s;

                &:after {
                    background: repeating-linear-gradient(
                        to right,
                        $gray 0,
                        $gray 4px,
                        transparent 4px,
                        transparent 8px
                    );
                }

                @media (hover: hover) {
                    &:hover {
                        opacity: 0.75;
                    }
                }
            }

            &:not(:last-of-type) + hr {
                width: calc(100% - #{rem(110px)});
                margin-left: rem(110px);
            }
        }

        hr {
            margin: rem(27px) 0;
        }
    }

    .description {
        font-size: rem(14px);
        line-height: 1.4;
    }

    .price {
        margin-bottom: rem(43px);

        font-size: rem(32px);
        font-weight: 500;

        &__currency {
            display: inline;

            margin-left: 0.4em;

            font-size: 0.8em;
        }
    }

    .buttons {
        display: flex;
        align-items: center;

        margin-bottom: rem(41px);

        .divider {
            width: 2px;
            height: rem(36px);
            margin: 0 rem(5px);

            background: url(../../../assets/images/divider-vertical.svg) repeat-y 0 0 / 100% auto;
        }
    }

    @include mobile_or_tablet {
        .product-slider {
            margin-right: rem(90px);
        }
    }

    @include mobile_or_P {
        padding-bottom: rem(56px);

        .app-breadcrumbs {
            margin-bottom: rem(20px);
        }

        .top {
            flex-wrap: wrap;

            margin-bottom: rem(16px);

            h2 {
                width: 100%;
                margin-bottom: rem(8px);
            }

            .code {
                order: 4;

                width: 100%;
                margin: 0 0 rem(16px) 0;

                &:before {
                    display: none;
                }
            }

            .favorites-button,
            .bin-preview {
                order: -1;

                margin-top: rem(-66px);
            }

            .favorites-button {
                margin-right: rem(7px);
            }

            hr {
                margin: rem(23px) 0 rem(28px);
            }

            .product-tags {
                position: static;

                order: 5;
            }
        }

        .wrapper {
            flex-direction: column;
        }

        .product-slider {
            margin: 0 auto rem(18px);
        }

        .product-info {
            max-width: 20rem;
            margin: 0 auto;
            padding-top: rem(30px);
        }

        .tabs {
            &__divider {
                margin: 0 rem(18px);
            }
        }

        .characteristics {
            &-item {
                .determine-size {
                    margin-top: rem(16px);
                }
            }

            hr {
                margin: rem(24px) 0 rem(22px);
            }
        }

        .buttons {
            flex-wrap: wrap;

            .sharing,
            .favorites-toggler {
                margin-bottom: rem(14px);
            }
        }

        @include landscape {
            .top {
                max-width: 20rem;
                margin: 0 auto;
            }
        }
    }
}
</style>
