<script>
export default {
    name: 'new-footer',
    data: () => ({
        navLinksIsExpanded: {
            0: false,
            1: false,
            2: false,
            3: false,
        },
        tabs: [
            {
                name: 'О ПРОДУКТЕ',
                isActive: true,
                isExpanded: false,
            },
            {
                name: 'КРЕДИТНЫЕ ПРОГРАММЫ',
                isActive: false,
                isExpanded: false,
            },
            {
                name: 'ЦЕНЫ И ВЫГОДЫ',
                isActive: false,
                isExpanded: false,
            },
        ],
        dropdowns: {
            city: false,
            pro: false,
        },
    }),
    methods: {
        toggleDropdown(name) {
            switch (name) {
                case 'city':
                    this.dropdowns.city = !this.dropdowns.city;
                    break;
                case 'pro':
                    this.dropdowns.pro = !this.dropdowns.pro;
                    break;
            }
        },

        setTabIndex(e) {
            this.tabs.map((tab, index) => {
                this.tabs[index].isActive = tab.name === e.target.innerText;
            });
        },

        expandTab(index) {
            this.tabs[index].isExpanded = !this.tabs[index].isExpanded;
        },

        expandMenuItem(index) {
            this.navLinksIsExpanded[index] = !this.navLinksIsExpanded[index];
        },
    },
};
</script>

<template>
    <footer>
        <div class="footer">
            <div class="footer__menu-model p-m">
                <div class="container footer__menu-model-items">
                    <div class="footer__menu-model-item">
                        <div
                            class="footer__menu-model-title"
                            :class="{ 'footer__menu-model-title--closed': !dropdowns.city }"
                            @click="toggleDropdown('city')"
                        >
                            <div class="footer__label footer__label--city">CITY</div>
                        </div>
                        <div
                            class="footer__collapse-content"
                            :class="{ 'footer__collapse-content--opened': dropdowns.city }"
                        >
                            <div class="pt-s footer__menu-model-content">
                                <a
                                    href="https://haval.ru/models/haval-f7-new2024/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    Новый F7
                                </a>
                                <a
                                    href="https://haval.ru/models/haval-jolion-new2024/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    JOLION
                                </a>
                                <a
                                    href="https://haval.ru/models/new-haval-m6/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    M6
                                </a>
                                <a
                                    href="https://haval.ru/models/new-haval-dargo-x/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    DARGO Х
                                </a>
                                <a
                                    href="https://haval.ru/models/new-haval-dargo/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    DARGO
                                </a>
                                <a
                                    v-if="false"
                                    href="https://haval.ru/models/new-haval-f7/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    F7
                                </a>
                                <a
                                    href="https://haval.ru/models/new-haval-f7x/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    F7x
                                </a>
                                <a
                                    href="https://haval.ru/models/poer/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    GWM POER
                                </a>
                                <a
                                    href="https://haval.ru/models/poer-king-kong/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    GWM POER KINGKONG
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="footer__menu-model-item">
                        <div
                            class="footer__menu-model-title"
                            :class="{ 'footer__menu-model-title--closed': !dropdowns.pro }"
                            @click="toggleDropdown('pro')"
                        >
                            <div class="footer__label footer__label--pro">PRO</div>
                        </div>
                        <div
                            class="footer__collapse-content"
                            :class="{ 'footer__collapse-content--opened': dropdowns.pro }"
                        >
                            <div class="pt-s footer__menu-model-content">
                                <a
                                    href="https://haval.ru/models/haval-h3/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    H3
                                </a>
                                <a
                                    href="https://haval.ru/models/haval-h9-new/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    H9
                                </a>
                                <a
                                    href="https://haval.ru/models/haval-h5/"
                                    class="footer__menu-model-link extrasmall-text"
                                >
                                    H5
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-xl">
                <div class="container">
                    <nav class="footer__menu">
                        <div class="row">
                            <div class="footer__menu-wrap col-lg-3">
                                <div class="footer__menu-item footer__collapse">
                                    <div
                                        class="footer__collapse-header footer__menu-title mb-s extrasmall-text"
                                        :class="{
                                            'footer__collapse-header--closed':
                                                !navLinksIsExpanded[0],
                                        }"
                                        @click="expandMenuItem(0)"
                                    >
                                        Сервисы
                                    </div>
                                    <div
                                        class="footer__collapse-content"
                                        :class="{
                                            'footer__collapse-content--opened':
                                                navLinksIsExpanded[0],
                                        }"
                                    >
                                        <div class="js-footer-collapse-inner-content">
                                            <div>
                                                <a
                                                    href="https://haval.ru/online-stock/#/models"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Автомобили в наличии
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/choose-and-offer/test-drive/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Заказать тест-драйв
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/credit/calculator/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Рассчитать кредит
                                                </a>
                                                <a
                                                    href="https://haval.ru/owners/request/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Записаться на сервис
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/find-dealer/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Найти дилера
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="footer__menu-wrap col-lg-3">
                                <div class="footer__menu-item footer__collapse">
                                    <div
                                        class="footer__collapse-header footer__menu-title mb-s extrasmall-text"
                                        :class="{
                                            'footer__collapse-header--closed':
                                                !navLinksIsExpanded[1],
                                        }"
                                        @click="expandMenuItem(1)"
                                    >
                                        Покупателям
                                    </div>
                                    <div
                                        class="footer__collapse-content"
                                        :class="{
                                            'footer__collapse-content--opened':
                                                navLinksIsExpanded[1],
                                        }"
                                    >
                                        <div class="js-footer-collapse-inner-content">
                                            <div>
                                                <a
                                                    href="https://haval.ru/purchase/choose-and-offer/online-stock-new/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Автомобили в наличии
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/choose-and-offer/shop/"
                                                    rel="nofollow"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Аксесcуары HAVAL
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/special-offer/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Все специальные предложения
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/special-offer/gosprogramma/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Программа Господдержки
                                                </a>
                                                <a
                                                    href="https://haval.ru/owners/haval-protection/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Программа «HAVAL Protection +»
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/catalogues/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Каталоги и прайс-листы
                                                </a>
                                                <a
                                                    href="https://haval.ru/trade-in/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Трейд-ин
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/find-dealer/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Найти дилера
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/choose-and-offer/test-drive/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Тест-драйв
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/credit/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    HAVAL Credit
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/insurance/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Страхование
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/credit/calculator/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Кредитный калькулятор
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/corporate_clients/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Корпоративным клиентам
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/corporate_clients/platform/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Платформа HAVAL
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/corporate_clients/business/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Для малого бизнеса
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/corporate_clients/service/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Сервис для корпоративных клиентов
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/corporate_clients/large_clients/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Крупным корпоративным клиентам
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/corporate_clients/corporate-leasing/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    HAVAL Лизинг
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="footer__menu-wrap col-lg-3">
                                <div class="footer__menu-item footer__collapse">
                                    <div
                                        class="footer__collapse-header footer__menu-title mb-s extrasmall-text"
                                        :class="{
                                            'footer__collapse-header--closed':
                                                !navLinksIsExpanded[2],
                                        }"
                                        @click="expandMenuItem(2)"
                                    >
                                        Владельцам
                                    </div>
                                    <div
                                        class="footer__collapse-content"
                                        :class="{
                                            'footer__collapse-content--opened':
                                                navLinksIsExpanded[2],
                                        }"
                                    >
                                        <div class="js-footer-collapse-inner-content">
                                            <div>
                                                <a
                                                    href="https://haval.ru/owners/request/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Запись на сервис
                                                </a>
                                                <a
                                                    href="https://haval.ru/offers/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Сервисные акции
                                                </a>
                                                <a
                                                    href="https://haval.ru/offers/extended/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Расширенная поддержка HAVAL
                                                </a>
                                                <a
                                                    href="https://haval.ru/maintenance-calculator/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Стоимость ТО
                                                </a>
                                                <!--                                                <a-->
                                                <!--                                                    href="https://haval.ru/owners/guarantee/"-->
                                                <!--                                                    class="footer__menu-link wellington-font small-text"-->
                                                <!--                                                >-->
                                                <!--                                                    Гарантийное обслуживание-->
                                                <!--                                                </a>-->
                                                <a
                                                    href="https://haval.ru/owners/epts/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Электронный ПТС
                                                </a>
                                                <a
                                                    href="https://haval.ru/owners/roadside-assistance/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Программа «Помощь на дороге»
                                                </a>
                                                <a
                                                    href="https://haval.ru/owners/regulations-to/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Регламенты технического обслуживания
                                                </a>
                                                <a
                                                    href="https://haval.ru/owners/guarantee/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Гарантия HAVAL
                                                </a>
                                                <a
                                                    href="https://haval.ru/owners/zabota-v-detalyah/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Забота в деталях
                                                </a>
                                                <a
                                                    href="https://haval.ru/offers/zimnie-shiny/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Зимние шины
                                                </a>
                                                <a
                                                    href="https://haval.ru/owners/my-haval/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Мобильное приложение MY HAVAL
                                                </a>
                                                <a
                                                    href="https://haval.ru/owners/haval-protection/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Программа «HAVAL Protection +»
                                                </a>
                                                <!--                                                <a-->
                                                <!--                                                    href="https://haval.ru/owners/roadside-assistance/"-->
                                                <!--                                                    class="footer__menu-link wellington-font small-text"-->
                                                <!--                                                >-->
                                                <!--                                                    Программа «Помощь на дороге»-->
                                                <!--                                                </a>-->
                                                <!--                                                <a-->
                                                <!--                                                    href="https://haval.ru/owners/regulations-to/"-->
                                                <!--                                                    class="footer__menu-link wellington-font small-text"-->
                                                <!--                                                >-->
                                                <!--                                                    Регламенты технического обслуживания-->
                                                <!--                                                </a>-->
                                                <a
                                                    href="https://haval.ru/owners/instructions/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Руководства по эксплуатации
                                                </a>
                                                <!--                                                <a-->
                                                <!--                                                    href="https://haval.ru/owners/epts/"-->
                                                <!--                                                    class="footer__menu-link wellington-font small-text"-->
                                                <!--                                                >-->
                                                <!--                                                    Электронный ПТС-->
                                                <!--                                                </a>-->
                                                <!--                                                <a-->
                                                <!--                                                    href="https://shop.haval.ru/"-->
                                                <!--                                                    rel="nofollow"-->
                                                <!--                                                    class="footer__menu-link wellington-font small-text"-->
                                                <!--                                                >-->
                                                <!--                                                    Каталог аксессуаров-->
                                                <!--                                                </a>-->
                                                <a
                                                    href="https://shop.haval.ru/products?category=city-1&collections=haval_city"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Коллекция CITY
                                                </a>
                                                <a
                                                    href="https://shop.haval.ru/products?category=pro-1"
                                                    rel="nofollow"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Коллекция PRO
                                                </a>
                                                <a
                                                    href="https://shop.haval.ru/products?category=basic"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Коллекция BASIC
                                                </a>
                                                <a
                                                    href="https://shop.haval.ru/products?category=kids-1"
                                                    rel="nofollow"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Коллекция KIDS
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="footer__menu-wrap col-lg-3">
                                <div class="footer__menu-item footer__collapse">
                                    <div
                                        class="footer__collapse-header footer__menu-title mb-s extrasmall-text"
                                        :class="{
                                            'footer__collapse-header--closed':
                                                !navLinksIsExpanded[3],
                                        }"
                                        @click="expandMenuItem(3)"
                                    >
                                        O HAVAL
                                    </div>
                                    <div
                                        class="footer__collapse-content"
                                        :class="{
                                            'footer__collapse-content--opened':
                                                navLinksIsExpanded[3],
                                        }"
                                    >
                                        <div class="js-footer-collapse-inner-content">
                                            <div>
                                                <a
                                                    href="https://haval.ru/become_dealer/actual-dealer/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Дилерская сеть CITY
                                                </a>
                                                <a
                                                    href="https://haval.ru/become_dealer/become-new-dealer/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Дилерская сеть PRO
                                                </a>
                                                <a
                                                    href="https://haval.ru/about/brand/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    О бренде
                                                </a>
                                                <a
                                                    href="https://haval.ru/about/cybersport/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    HAVAL и киберспорт
                                                </a>
                                                <a
                                                    href="https://haval.ru/about/development/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Производство в России
                                                </a>
                                                <a
                                                    href="https://haval.ru/purchase/find-dealer/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Список официальных дилеров
                                                </a>
                                                <a
                                                    href="https://haval.ru/about/media/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Новости
                                                </a>
                                                <a
                                                    href="https://haval.ru/about/contacts/"
                                                    class="footer__menu-link wellington-font small-text"
                                                >
                                                    Контакты
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div class="footer__social-wrap">
                        <div class="footer__social mb-s">
                            <a
                                href="https://vk.com/havalrussia"
                                rel="nofollow"
                                class="footer__social-item"
                                target="_blank"
                            >
                                <img
                                    class="svg-icon footer__social-icon"
                                    src="@/assets/images/icons/socials/vk_new.svg"
                                />
                                <span>vk</span>
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCjAYkOQxlQRKFUNkWXz19VQ"
                                rel="nofollow"
                                class="footer__social-item"
                                target="_blank"
                            >
                                <img
                                    class="svg-icon footer__social-icon"
                                    src="@/assets/images/icons/socials/youtube_new.svg"
                                />
                                <span>youtube</span>
                            </a>
                            <a
                                href="https://t.me/+Wg1zN8_m8Pg3ZThi"
                                rel="nofollow"
                                class="footer__social-item"
                            >
                                <img
                                    class="svg-icon footer__social-icon"
                                    src="@/assets/images/icons/socials/telegram_new.svg"
                                />
                                <span>tg</span>
                            </a>
                        </div>
                    </div>
                    <div class="footer__disclaimer p-m">
                        <ul class="footer__disclaimer-nav mb-xs">
                            <li
                                class="js-footer-disc-nav-btn footer__disclaimer-nav-btn brand-tiny"
                                :class="{ active: tabs[0].isActive }"
                                @click="setTabIndex"
                            >
                                О продукте
                            </li>
                            <li
                                class="js-footer-disc-nav-btn footer__disclaimer-nav-btn brand-tiny"
                                :class="{ active: tabs[1].isActive }"
                                @click="setTabIndex"
                            >
                                Кредитные программы
                            </li>
                            <li
                                class="js-footer-disc-nav-btn footer__disclaimer-nav-btn brand-tiny"
                                :class="{ active: tabs[2].isActive }"
                                @click="setTabIndex"
                            >
                                Цены и выгоды
                            </li>
                        </ul>

                        <div class="footer__disclaimer-content">
                            <div
                                class="js-footer-disc-pane footer__disclaimer-pane"
                                :class="{ active: tabs[0].isActive }"
                            >
                                <div
                                    :class="{
                                        'footer__disclaimer-pane-text--expanded':
                                            tabs[0].isExpanded,
                                    }"
                                    class="footer__disclaimer-pane-text wellington-font extrasmall-text"
                                >
                                    <p>
                                        Вся представленная на сайте информация, касающаяся
                                        автомобилей и сервисного обслуживания, носит информационный
                                        характер и не является публичной офертой. Все цены,
                                        указанные на данном сайте, носят информационный характер и
                                        являются максимально рекомендуемыми розничными ценами по
                                        расчетам дистрибьютора (ООО «Хавейл Мотор Рус»). Для
                                        получения подробной информации просьба обращаться к
                                        ближайшему официальному дилеру ООО «Хавейл Мотор Рус» либо
                                        по телефону Горячей линии 8 (800) 511-59-86, либо на сайте.
                                        Опубликованная на данном сайте информация может быть
                                        изменена в любое время без предварительного уведомления.
                                    </p>
                                    <p>1 Хавейл M6</p>
                                    <p>2 Хавейл Да́рго</p>
                                    <p>3 Хавейл Да́рго Икс</p>
                                    <p>4 Хавейл Джо́лион</p>
                                    <p>5 Хавейл Ф7</p>
                                    <p>6 Хавейл Ф7 Икс</p>
                                    <p>7 Хавейл Эйч 9</p>
                                    <p>8 Грейт Вол Мóтор Пóэр Кингконг</p>
                                    <p>9 Грейт Вол Мóтор Пóэр</p>
                                    <p>10 Хавейл Эйч 3</p>
                                    <p>11 Хавейл Сити</p>
                                    <p>12 Хавейл Про</p>
                                    <p>13 Хавейл Эйч 5</p>
                                    <p>&nbsp;</p>
                                    <p>
                                        **** На некоторых автомобилях HAVAL может отсутствовать
                                        система / устройство вызова экстренных оперативных служб
                                        (блок ЭРА-ГЛОНАСС).
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                                <button
                                    class="js-footer-disc-pane-btn footer__disclaimer-pane-btn mt-xs wellington-font"
                                    :class="{
                                        'footer__disclaimer-pane-btn--closed': !tabs[0].isExpanded,
                                    }"
                                    @click="expandTab(0)"
                                >
                                    Показать все
                                </button>
                            </div>
                            <div
                                class="js-footer-disc-pane footer__disclaimer-pane"
                                :class="{ active: tabs[1].isActive }"
                            >
                                <div
                                    :class="{
                                        'footer__disclaimer-pane-text--expanded':
                                            tabs[1].isExpanded,
                                    }"
                                    class="footer__disclaimer-pane-text wellington-font extrasmall-text"
                                >
                                    <p>
                                        ***Предложение по тарифному плану «Оптимальный Плюс»,
                                        Кредитный продукт «Оптимальный плюс 2024» распространяется
                                        на новые автомобили Бренда HAVAL модели JOLION, М6, F7x,
                                        DARGO (включая специальную версию DARGO X) 2024 года
                                        выпуска. Диапазон полной стоимости потребительского кредита
                                        (ПСК) в % годовых от 0,010% до 20,383%, в рублях от 5,30
                                        руб. до 5 382 312,33 руб. Процентная ставка по кредиту от
                                        0,01% до 15,4% годовых, первоначальный взнос от 60% до 80%
                                        от стоимости автомобиля, либо процентная ставка по кредиту
                                        от 0,01% до 19,4% годовых, первоначальный взнос от 40% до
                                        59,99% от стоимости автомобиля, либо процентная ставка по
                                        кредиту от 10,9% до 20,4% годовых, первоначальный взнос от
                                        10% до 39,99% от стоимости автомобиля. Сумма кредита от 100
                                        000 руб. до 6 000 000 руб., срок кредитования от 12 до 84
                                        мес. Условия по кредиту и тарифы могут быть изменены Банком
                                        в одностороннем порядке. Банк вправе отказать в выдаче
                                        автокредита. Обеспечение по кредиту — залог приобретаемого
                                        автомобиля. Страхование по КАСКО HAVAL Insurance. Условия
                                        кредитования актуальны на 01.08.2024 г., подробнее уточняйте
                                        в официальных дилерских центрах HAVAL. Предложение носит
                                        информационный характер, не является публичной офертой.
                                        Кредит предоставляется ООО «Драйв Клик Банк» ИНН 6452010742,
                                        ОГРН 1027739664260, место нахождения: 125124 г. Москва, ул.
                                        Правды, д. 26. Лицензия Банка России № 2168 (бессрочная) от
                                        09.12.2022.
                                    </p>
                                    <p>
                                        Вся представленная на сайте информация, касающаяся
                                        автомобилей и сервисного обслуживания, носит информационный
                                        характер и не является публичной офертой. Все цены,
                                        указанные на данном сайте, носят информационный характер и
                                        являются максимально рекомендуемыми розничными ценами по
                                        расчетам дистрибьютора (ООО «Хавейл Мотор Рус»). Для
                                        получения подробной информации просьба обращаться к
                                        ближайшему официальному дилеру ООО «Хавейл Мотор Рус» либо
                                        по телефону Горячей линии 8 (800) 511-59-86, либо на сайте.
                                        Опубликованная на данном сайте информация может быть
                                        изменена в любое время без предварительного уведомления.
                                    </p>
                                    <p>
                                        ****Предложение по тарифному плану «Оптимальный: Плюс»
                                        кредитный продукт «ОПТИМАЛЬНЫЙ: ПЛЮС ПРО» распространяется
                                        на новые автомобили Бренда HAVAL модели H9 2024 года
                                        выпуска. Диапазон полной стоимости потребительского кредита
                                        (ПСК) в % годовых от 0,010% до 0,010%; в рублях РФ от 5,30
                                        до 352,63 руб. Указанная процентная ставка по кредиту 0,01%
                                        годовых достигается при следующих условиях кредитования:
                                        первоначальный взнос от 70% и не более 80% от стоимости
                                        автомобиля, сумма кредита от 100 000 руб. до 6 000 000 руб.,
                                        срок кредитования 12 мес. Условия по кредиту и тарифы могут
                                        быть изменены Банком в одностороннем порядке. Банк вправе
                                        отказать в выдаче автокредита. Обеспечение по кредиту —
                                        залог приобретаемого автомобиля. Страхование по КАСКО HAVAL
                                        Insurance. Условия кредитования актуальны на 09.08.2024 г.,
                                        подробнее уточняйте в официальных дилерских центрах HAVAL.
                                        Предложение носит информационный характер, не является
                                        публичной офертой. Кредит предоставляется ООО «Драйв Клик
                                        Банк» ИНН 6452010742, ОГРН 1027739664260, место нахождения:
                                        125124 г. Москва, ул. Правды, д. 26. Лицензия Банка России №
                                        2168 (бессрочная) от 09.12.2022.
                                    </p>
                                </div>
                                <button
                                    class="js-footer-disc-pane-btn footer__disclaimer-pane-btn mt-xs wellington-font"
                                    :class="{
                                        'footer__disclaimer-pane-btn--closed': !tabs[1].isExpanded,
                                    }"
                                    @click="expandTab(1)"
                                >
                                    Показать все
                                </button>
                            </div>
                            <div
                                class="js-footer-disc-pane footer__disclaimer-pane"
                                :class="{ active: tabs[2].isActive }"
                            >
                                <div
                                    :class="{
                                        'footer__disclaimer-pane-text--expanded':
                                            tabs[2].isExpanded,
                                    }"
                                    class="footer__disclaimer-pane-text wellington-font extrasmall-text"
                                >
                                    <ul>
                                        <li>
                                            <p>
                                                Выгода 150 000 рублей предусмотрена при приобретении
                                                HAVAL M6 по специальному предложению. Предложение
                                                ограничено, не является офертой и действует с
                                                14.10.2024 года. Подробности о специальном
                                                предложении уточняйте в официальных дилерских
                                                центрах HAVAL.
                                            </p>
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            Выгода до 300 000 рублей предусмотрена при приобретении
                                            HAVAL H3 в комплектациях Premium и Tech Plus, 2024 года
                                            производства по программе HAVAL PRO Trade-In + Credit
                                            (Хавейл Про Трейд-ин + Кредит). Финансовые услуги
                                            оказывает ПАО РОСБАНК, генеральная лицензия №2272 от
                                            28.01.15, ИНН 7730060164, ОГРН 1027739460737, адрес:
                                            107078, Россия, Москва, ул. Маши Порываевой, д. 34.
                                            Подробности уточняйте в дилерских центрах HAVAL Pro и на
                                            сайте
                                            <a href="https://www.haval.ru" target="_blank">
                                                www.haval.ru
                                            </a>
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            Выгода 200 000 рублей предусмотрена при приобретении
                                            HAVAL DARGO по программе трейд-ин, актуальна на 16 мая
                                            2024 года. Предложение ограничено, не является офертой.
                                            Подробности на сайте
                                            <a href="https://www.haval.ru" target="_blank">
                                                haval.ru
                                            </a>
                                            и у официальных дилеров HAVAL.
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            Выгода 300 000 рублей предусмотрена при приобретении
                                            HAVAL H3 в комплектациях Premium и Tech Plus, 2024 года
                                            производства по программе HAVAL PRO Trade-In + Credit
                                            (“Хавейл Про трейд-ин + кредит”) при сдаче дилеру
                                            бывшего в употреблении (б/у) автомобиля марки HAVAL или
                                            Great Wall с пробегом со сроком владения и регистрации
                                            (постановки на учет) в органах ГИБДД не менее 3 месяцев
                                            до сдачи автомобиля в трейд-ин. В качестве документов,
                                            подтверждающих срок владения сдаваемого в трейд-ин
                                            автомобиля, собственнику необходимо предоставить копию
                                            ПТС или СТС или карточку учета ТС из ГИБДД с печатью и
                                            подписью. Финансовые услуги оказывает ПАО РОСБАНК,
                                            генеральная лицензия №2272 от 28.01.15, ИНН 7730060164,
                                            ОГРН 1027739460737, адрес: 107078, Россия, Москва, ул.
                                            Маши Порываевой, д. 34. Подробности уточняйте у
                                            официальных дилеров HAVAL PRO. Предложение ограничено,
                                            не является офертой и действует с 01.10.2024.
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            Цена автомобиля HAVAL H3 в комплектации Premium
                                            (Премиум) с двигателем 1.5Т, 2WD, 2024 года производства
                                            по программе Лояльный трейд-ин. Предложение ограничено,
                                            не является офертой и действует с 19.07.2024 года.
                                            Подробности уточняйте в официальных дилерских центрах
                                            HAVAL.
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            Цена обновленного автомобиля HAVAL JOLION 2024
                                            модельного года в комплектации Comfort (Комфорт) с
                                            двигателем 1.5Т, MT, 2WD, 2024 года производства.
                                            Предложение ограничено, не является офертой и действует
                                            с 14.10.2024 года. Подробности уточняйте в официальных
                                            дилерских центрах HAVAL.
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            Цена автомобиля HAVAL F7X в комплектации Comfort
                                            (Комфорт) с двигателем 1.5Т, 2WD, 2023 года
                                            производства. Предложение ограничено, не является
                                            офертой и действует с 01.02.2024 года. Подробности
                                            уточняйте в официальных дилерских центрах HAVAL.
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            Цена автомобиля HAVAL DARGO в комплектации Comfort
                                            (Комфорт) с двигателем 2.0T 2WD, 2024 года производства
                                            с учетом выгоды 100 000 рублей по программе трейд-ин.
                                            Предложение ограничено, не является офертой и действует
                                            с 14.10.2024 года. Подробности уточняйте в официальных
                                            дилерских центрах HAVAL.
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            Цена автомобиля HAVAL DARGO X в комплектации Elite
                                            (Элит) с двигателем 2.0T 4WD, 2024 года производства с
                                            учетом выгоды 100 000 рублей по программе трейд-ин.
                                            Предложение ограничено, не является офертой и действует
                                            с 17.07.2023 года. Подробности уточняйте в официальных
                                            дилерских центрах HAVAL.
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            Цена на модель GWM POER в комплектации Comfort (Комфорт)
                                            с дизельным двигателем 2.0Т 150, 4WD, 2023 года
                                            производства с учетом выгоды 200 000 руб. Предложение
                                            ограничено, не является офертой и действует с 01.02.2024
                                            года. Подробности уточняйте в официальных дилерских
                                            центрах HAVAL.
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>
                                            Цена автомобиля HAVAL H9 в комплектации Elite (Элит) с
                                            двигателем 2.0T, 2024 года производства с учетом выгоды
                                            300 000 рублей по программе Лояльный трейд-ин.
                                            Предложение ограничено, не является офертой и действует
                                            с 14.10.2024 года. Подробности о предложении уточняйте в
                                            официальных дилерских центрах HAVAL PRO.
                                        </li>
                                    </ul>
                                    <p></p>
                                    <ul>
                                        <li>
                                            Цена на модель GWM POER KINGKONG в комплектации Comfort
                                            (Комфорт) с дизельным двигателем 2,0d 150 л.с. 4WD, 2023
                                            года производства с учетом выгоды 300 000 руб.
                                            Предложение ограничено, не является офертой и действует
                                            с 01.02.2024 года. Подробности уточняйте в официальных
                                            дилерских центрах HAVAL.
                                        </li>
                                    </ul>
                                    <p></p>
                                    <ul>
                                        <li>
                                            Цена автомобиля HAVAL M6 с двигателем 1.5T, MТ, 2WD,
                                            2024 года производства с учетом выгоды 150 000 руб. по
                                            специальному предложению. Предложение ограничено, не
                                            является офертой и действует с 14.10.2024 года.
                                            Подробности о специальном предложении уточняйте в
                                            официальных дилерских центрах HAVAL.
                                        </li>
                                    </ul>
                                    <p></p>
                                    <ul>
                                        <li>
                                            Цена на модель GWM POER KINGKONG в комплектации Comfort
                                            (Комфорт) с дизельным двигателем 2,0d 150 л.с. 4WD, 2023
                                            года производства с учетом выгоды 300 000 руб.
                                            Предложение ограничено, не является офертой и действует
                                            с 01.02.2024 года. Подробности уточняйте в официальных
                                            дилерских центрах HAVAL.
                                        </li>
                                    </ul>
                                    <p></p>
                                    <ul>
                                        <li>
                                            Цена на модель нового поколения HAVAL F7 в комплектации
                                            Elite (Элит) с двигателем 1.5T, 2024 года производства.
                                            Предложение ограничено, не является офертой и действует
                                            с 23.08.2024 года. Подробности о предложении уточняйте в
                                            официальных дилерских центрах HAVAL CITY и на сайте
                                            <a target="_blank" href="https://haval.ru">haval.ru</a>
                                        </li>
                                    </ul>
                                    <p></p>
                                    <ul>
                                        <li>
                                            Цена автомобиля HAVAL H9 в комплектации Elite (Элит) с
                                            двигателем 2.0T, 2024 года производства с учетом выгоды
                                            300 000 рублей по программе Лояльный трейд-ин.
                                            Предложение ограничено, не является офертой и действует
                                            с 09.08.2024 года. Подробности о предложении уточняйте в
                                            официальных дилерских центрах HAVAL PRO.
                                        </li>
                                    </ul>
                                    <p></p>
                                    <ul>
                                        <li>
                                            Выгода 200 000 рублей предусмотрена при приобретении
                                            HAVAL H5 2024 года производства по программе Лояльный
                                            трейд-ин. В Лояльный трейд-ин принимаются автомобили
                                            марки HAVAL или Great Wall с пробегом со сроком владения
                                            и регистрации (постановки на учет) в органах ГИБДД не
                                            менее 3 месяцев до сдачи автомобиля в трейд-ин. В
                                            качестве документов, подтверждающих срок владения
                                            сдаваемого в трейд-ин автомобиля, собственнику
                                            необходимо предоставить копию ПТС или СТС или карточку
                                            учета ТС из ГИБДД с печатью и подписью. Подробности
                                            уточняйте у официальных дилеров HAVAL PRO или на сайте
                                            <a target="_blank" href="https://haval.ru">haval.ru</a>.
                                            Условия программы Лояльный трейд-ин не сочетаются с
                                            условиями программы Трейд-ин. Предложение ограничено, не
                                            является офертой и действует с 01.10.2024.
                                        </li>
                                    </ul>
                                    <p></p>
                                    <ul>
                                        <li>
                                            Выгода 200 000 рублей предусмотрена при приобретении
                                            HAVAL F7 2024 года производства по программе «Лояльный
                                            трейд-ин». Подробности уточняйте на сайте
                                            <a target="_blank" href="https://haval.ru">haval.ru</a>.
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <p>
                                        <a href="">
                                            **** На некоторых автомобилях HAVAL может отсутствовать
                                            система / устройство вызова экстренных оперативных служб
                                            (блок ЭРА-ГЛОНАСС).
                                        </a>
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>1 Хавейл M6</p>
                                    <p>2 Хавейл Да́рго</p>
                                    <p>3 Хавейл Да́рго Икс</p>
                                    <p>4 Хавейл Джо́лион</p>
                                    <p>5 Хавейл Ф7</p>
                                    <p>6 Хавейл Ф7 Икс</p>
                                    <p>7 Хавейл Эйч 9</p>
                                    <p>8 Грейт Вол Мóтор Пóэр Кингконг</p>
                                    <p>9 Грейт Вол Мóтор Пóэр</p>
                                    <p>10 Хавейл Эйч 3</p>
                                    <p>11 Хавейл Сити</p>
                                    <p>12 Хавейл Про</p>
                                    <p>13 Хавейл Эйч 5</p>
                                    <p>&nbsp;</p>
                                    <p>
                                        Вся представленная на сайте информация, касающаяся
                                        автомобилей и сервисного обслуживания, носит информационный
                                        характер и не является публичной офертой. Все цены,
                                        указанные на данном сайте, носят информационный характер и
                                        являются максимально рекомендуемыми розничными ценами по
                                        расчетам дистрибьютора (ООО «Хавейл Мотор Рус»). Для
                                        получения подробной информации просьба обращаться к
                                        ближайшему официальному дилеру ООО «Хавейл Мотор Рус» либо
                                        по телефону Горячей линии 8 (800) 511-59-86, либо на сайте.
                                        Опубликованная на данном сайте информация может быть
                                        изменена в любое время без предварительного уведомления.
                                    </p>
                                </div>
                                <button
                                    class="js-footer-disc-pane-btn footer__disclaimer-pane-btn mt-xs wellington-font"
                                    :class="{
                                        'footer__disclaimer-pane-btn--closed': !tabs[2].isExpanded,
                                    }"
                                    @click="expandTab(2)"
                                >
                                    Показать все
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="footer__bottom mt-s row">
                        <div class="footer__copy wellington-font small-text cg-6 col-lg-3">
                            © 2024 ООО «Хавейл Мотор Рус»
                        </div>
                        <a
                            href="https://haval.ru/privacy-policy/"
                            class="footer__submenu-item col-lg-3 small-text wellington-font"
                        >
                            Политика конфиденциальности
                        </a>
                        <a
                            href="https://haval.ru/user-agreement/"
                            class="footer__submenu-item col-lg-3 small-text wellington-font"
                        >
                            Юридическая информация
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped lang="scss">
.footer {
    margin-top: auto;

    background: #f2f3f4;

    &__menu-model {
        background: #fff;

        &.p-m {
            padding-top: 24px;
            padding-bottom: 24px;

            @media (min-width: 600px) {
                padding-top: 28px;
                padding-bottom: 28px;
            }
        }
    }

    .container {
        width: 100%;
        max-width: 1648px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 24px;
        padding-left: 24px;

        @media (min-width: 600px) {
            padding-right: 48px;
            padding-left: 48px;
        }

        @media (min-width: 1280px) {
            padding-right: 64px;
            padding-left: 64px;
        }

        @media (min-width: 1648px) {
            padding-right: 64px;
            padding-left: 64px;
        }
    }

    &__menu-model-items {
        @media (min-width: 1280px) {
            display: flex;
        }
    }

    &__menu-model-item {
        & .footer__collapse-content {
            @media (min-width: 1280px) {
                height: auto;
            }
        }

        &:first-child {
            padding-bottom: 16px;

            border-bottom: 1px solid rgba(16, 19, 22, 0.2);

            @media (min-width: 1280px) {
                padding: 0;

                border: none;
            }
        }

        &:not(:first-child) {
            padding-top: 16px;

            @media (min-width: 1280px) {
                margin-left: 64px;
                padding: 0;
            }
        }
    }

    &__menu-model-title {
        position: relative;

        display: flex;
        align-items: center;

        height: 24px;

        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            right: 5px;

            width: 14px;
            height: 2px;

            background: #121314;

            transition: all 0.3s;

            @media (min-width: 1280px) {
                display: none;

                pointer-events: none;
            }
        }

        &--closed::after {
            transform: rotate(90deg);
        }

        @media (min-width: 1280px) {
            height: auto;
        }
    }

    &__label {
        display: inline-block;

        margin-right: 24px;
        padding: 4px 3px 0 3px;

        font-family: Haval, Arial, Helvetica, sans-serif;
        font-size: 10px;
        line-height: 1;
        color: #fff;

        background-color: #00cabf;

        &--pro {
            background-color: #121314;
        }

        @media (min-width: 1280px) {
            font-size: 14px;
        }
    }

    &__collapse-header {
        position: relative;

        @media (min-width: 960px) {
            pointer-events: none;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            right: 5px;

            width: 14px;
            height: 2px;

            background: #121314;

            transition: all 0.3s;

            @media (min-width: 960px) {
                display: none;
            }
        }

        &--closed::after {
            transform: rotate(90deg);
        }
    }

    &__collapse-content {
        height: 0;
        overflow: hidden;

        transition: height 0.2s;

        &--opened {
            height: auto;
        }
    }

    .pt-s {
        padding-top: 16px;

        @media (min-width: 600px) {
            padding-top: 18px;
        }

        @media (min-width: 1280px) {
            padding-top: 24px;
        }
    }

    &__menu-model-content {
        @media (min-width: 600px) {
            display: grid;

            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
        }

        @media (min-width: 1280px) {
            display: flex;
            gap: 24px;
        }

        @media (min-width: 1648px) {
            padding-top: 24px;
        }
    }

    &__menu-model-link {
        display: block;

        font-family: Haval, Arial, Helvetica, sans-serif;
        font-weight: 500;
        color: #121314;
        text-decoration: none;

        &.extrasmall-text {
            font-size: 12px;
            line-height: 1.3;
        }

        &:not(:first-child) {
            margin-top: 16px;
        }

        @media (min-width: 600px) {
            margin: 0 !important;
        }
    }

    .p-xl {
        padding-top: 40px;
        padding-bottom: 40px;

        @media (min-width: 600px) {
            padding-top: 48px;
            padding-bottom: 48px;
        }

        @media (min-width: 1280px) {
            padding-top: 64px;
            padding-bottom: 64px;
        }

        @media (min-width: 1648px) {
            padding-top: 72px;
            padding-bottom: 72px;
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;

        margin-right: -8px;
        margin-left: -8px;

        @media (min-width: 600px) {
            margin-right: -12px;
            margin-left: -12px;
        }

        @media (min-width: 1280px) {
            display: flex;
            flex-wrap: wrap;

            margin-right: -16px;
            margin-left: -16px;
        }

        @media (min-width: 1648px) {
            display: flex;
            flex-wrap: wrap;

            margin-right: -20px;
            margin-left: -20px;
        }
    }

    &__menu-wrap:not(:first-child) {
        margin-top: 16px;
    }

    .col-lg-3 {
        @media (min-width: 960px) {
            flex: 0 0 25%;

            max-width: 25%;
        }
    }

    .row > .col,
    .row > [class*='col-'] {
        position: relative;

        width: 100%;
        padding-right: 8px;
        padding-left: 8px;

        @media (min-width: 600px) {
            position: relative;

            width: 100%;
            padding-right: 12px;
            padding-left: 12px;
        }

        @media (min-width: 1280px) {
            position: relative;

            width: 100%;
            padding-right: 16px;
            padding-left: 16px;
        }

        @media (min-width: 1648px) {
            position: relative;

            width: 100%;
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &__menu-item {
        width: 100%;

        border-bottom: 1px solid rgba(16, 19, 22, 0.2);

        @media (min-width: 960px) {
            border: none;
        }

        & .footer__collapse-content {
            @media (min-width: 960px) {
                height: auto;
            }
        }
    }

    & a {
        text-decoration: none;

        @media (min-width: 960px) {
            margin-bottom: 12px;
        }
    }

    &__menu-title {
        position: relative;

        display: flex;
        align-items: center;

        height: 24px;
        padding-right: 32px;

        font-family: Haval, Arial, Helvetica, sans-serif;
        font-weight: 500;
        color: #121314;
        text-transform: uppercase;

        cursor: pointer;

        @media (min-width: 960px) {
            padding: 0;
        }
    }

    .mb-s {
        margin-bottom: 16px;

        @media (min-width: 600px) {
            margin-bottom: 18px;
        }

        @media (min-width: 1648px) {
            margin-bottom: 32px;
        }
    }

    .extrasmall-text {
        font-size: 12px;
        line-height: 1.3;

        @media (min-width: 1648px) {
            font-size: 14px;
            line-height: 1.4;
        }
    }

    &__collapse-content {
        overflow: hidden;

        transition: height 0.2s;
    }

    &__menu-link {
        display: block;

        margin-bottom: 12px;

        color: #121314;

        @media (min-width: 960px) {
            margin: 0;

            &:not(:last-of-type) {
                margin-bottom: 12px;
            }
        }

        @media (min-width: 1648px) {
            &:not(:last-of-type) {
                margin-bottom: 16px;
            }
        }
    }

    .small-text {
        font-size: 14px;
        line-height: 1.4;

        @media (min-width: 1648px) {
            font-size: 16px;
        }
    }

    &__social-wrap {
        border-bottom: 1px solid rgba(16, 19, 22, 0.2);
    }

    &__social {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        margin-top: 16px;

        @media (min-width: 960px) {
            justify-content: flex-start;

            margin-top: 36px;
        }

        @media (min-width: 1648px) {
            margin-top: 56px;
        }
    }

    &__social-item {
        display: flex;

        padding: 10px;

        @media (min-width: 960px) {
            margin-bottom: 0 !important;
        }
    }

    .svg-icon {
        overflow: hidden;
    }

    &__social-icon {
        width: 20px;
        height: 20px;

        fill: #121314;
    }

    &__social-item span {
        display: none;
    }

    &__disclaimer {
        border-bottom: 1px solid rgba(16, 19, 22, 0.2);
    }

    .p-m {
        padding-top: 24px;
        padding-bottom: 24px;

        @media (min-width: 600px) {
            padding-top: 28px;
            padding-bottom: 28px;
        }

        @media (min-width: 1280px) {
            padding-top: 36px;
            padding-bottom: 36px;
        }

        @media (min-width: 1648px) {
            padding-top: 44px;
            padding-bottom: 44px;
        }
    }

    .mb-xs {
        margin-bottom: 10px;

        @media (min-width: 600px) {
            margin-bottom: 12px;
        }

        @media (min-width: 1648px) {
            margin-bottom: 16px;
        }
    }

    &__disclaimer-nav {
        display: flex;
        flex-direction: column;

        list-style: none;

        @media (min-width: 600px) {
            flex-direction: row;
            align-items: center;
        }
    }

    .brand-tiny {
        font-family: Haval, Arial, Helvetica, sans-serif;
        font-size: 10px;
        line-height: 1.4;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.2px;

        @media (min-width: 1648px) {
            font-size: 12px;
            line-height: 1.3;
        }
    }

    &__disclaimer-nav-btn {
        color: rgba(16, 19, 22, 0.6);

        cursor: pointer;

        &:not(:last-of-type) {
            margin-bottom: 18px;

            @media (min-width: 600px) {
                margin-right: 24px;
                margin-bottom: 0;
            }
        }

        &.active {
            color: #121314;

            cursor: default;
        }
    }

    &__disclaimer-pane {
        display: none;

        &.active {
            display: block;
        }
    }

    &__disclaimer-pane-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        max-height: 3em;
        overflow: hidden;

        color: rgba(16, 19, 22, 0.6);

        transition: max-height 0.3s ease-in-out;

        &--expanded {
            -webkit-line-clamp: unset;

            max-height: none;
        }

        & a {
            color: #007aff;
        }
    }

    .mt-xs {
        margin-top: 10px;

        @media (min-width: 600px) {
            margin-top: 12px;
        }

        @media (min-width: 1280px) {
            margin-top: 12px;
        }

        @media (min-width: 1648px) {
            margin-top: 16px;
        }
    }

    &__disclaimer-pane-btn {
        position: relative;

        display: flex;
        align-items: center;

        padding-right: 28px;
        padding-left: 0;

        font-size: 13px;

        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            right: 0;

            width: 12px;
            height: 2px;

            background: #121314;

            transition: all 0.3s;
        }

        &--closed::after {
            transform: rotate(90deg);
        }
    }

    &__bottom {
        text-align: center;

        @media (min-width: 960px) {
            display: flex;

            text-align: left;
        }
    }

    .mt-s {
        margin-top: 16px;

        @media (min-width: 600px) {
            margin-top: 18px;
        }

        @media (min-width: 1280px) {
            margin-top: 24px;
        }

        @media (min-width: 1648px) {
            margin-top: 32px;
        }
    }

    &__submenu-item {
        display: block;

        margin-top: 6px;

        color: inherit;

        @media (min-width: 600px) {
            margin-top: 8px;
        }

        @media (min-width: 960px) {
            margin-top: 0;
        }
    }
}
</style>
