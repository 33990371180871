<template lang="pug">
    .product-slider
        .swiper-container(ref="product-slider")
            .swiper-wrapper
                .swiper-slide(
                    v-for="media in activeGroupMedia"
                    :style="{ backgroundImage: 'url('+getImageUrl(media.image)+')'}"
                )

        .swiper-pagination(ref="product-pagination")
        .product-slider-groups
            .product-slider-groups__item(
                v-for="group in productGroups"
                :style="{ backgroundColor: group.color.code}"
                :class="{ active: activeGroup === group }"
                @click="activeGroup = group"
            )
        .swiper-button-prev(ref="product-slider-prev")
        .swiper-button-next(ref="product-slider-next")
</template>

<script>
import Swiper from 'swiper';
import getImageUrl from '@/js/utils/get-image-url';

export default {
    name: 'product-slider',

    props: {
        value: {
            type: Object,
            default: null,
        },

        productGroups: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        slider: null,
    }),

    computed: {
        activeGroup: {
            get() {
                return this.value;
            },
            set(value) {
                if (value.id === this.activeGroup?.id) return;
                this.$emit('input', value);
                this.destroySlider();
                setTimeout(this.initSlider);
            },
        },

        activeGroupMedia() {
            return this.activeGroup?.media.entries;
        },
    },

    mounted() {
        this.initSlider();
    },

    methods: {
        destroySlider() {
            this.slider?.destroy();
        },

        initSlider() {
            this.slider = new Swiper(this.$refs['product-slider'], {
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                loop: true,
                watchOverflow: true,
                navigation: {
                    prevEl: this.$refs['product-slider-prev'],
                    nextEl: this.$refs['product-slider-next'],
                },
                pagination: {
                    el: this.$refs['product-pagination'],
                    clickable: true,
                },
            });
        },

        getImageUrl,
    },
};
</script>

<style scoped lang="scss">
.product-slider {
    position: relative;

    display: flex;
    flex-direction: column;

    width: rem(575px);
    height: rem(520px);

    .swiper-container {
        width: 92%;
        height: 100%;
        margin-bottom: rem(60px);
    }

    .swiper-slide {
        background: no-repeat center center / contain;
    }

    .swiper-pagination {
        position: static;

        display: flex;
        justify-content: center;

        margin-bottom: rem(21px);

        &::v-deep {
            .swiper-pagination-bullet {
                width: rem(7px);
                height: rem(7px);
                margin: 0 rem(10px);

                background: $gray;
            }
        }
    }

    &-groups {
        display: flex;
        justify-content: center;

        &__item {
            position: relative;

            flex-shrink: 0;

            width: rem(22px);
            height: rem(22px);
            margin: 0 rem(27px);

            border-radius: 50%;

            cursor: pointer;
            opacity: 1;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;

                width: rem(38px);
                height: rem(38px);

                background: inherit;
                border-radius: 50%;

                transform: translate(-50%, -50%) scale(0.5);
                opacity: 0.2;
                transition: transform .2s;
            }

            &.active:before {
                transform: translate(-50%, -50%);
            }
        }
    }

    .swiper-button {
        &-prev, &-next {
            top: 35%;
            z-index: 1;

            width: rem(32px);
            height: rem(32px);
            margin: 0;

            background: url(../../../assets/images/icons/arrow-slider.svg) no-repeat center center / 30% auto #C4C4C4;
            border-radius: 50%;

            transition: background-color .2s;

            &:after {
                display: none;
            }

            &:hover {
                background-color: $gray;
            }
        }

        &-prev {
            left: rem(-14px);
        }

        &-next {
            right: rem(-14px);

            transform: scaleX(-1);
        }
    }

    @include mobile_or_P {
        width: 100%;
        max-width: 20rem;
        height: rem(295px);

        .swiper-container {
            width: rem(230px);
            margin-bottom: rem(45px);
        }

        &-groups {
            &__item {
                margin: 0 rem(17px);
            }
        }

        .swiper-button {
            &-prev, &-next {
                top: 27%;
            }

            &-prev {
                left: 0;
            }

            &-next {
                right: 0;
            }
        }
    }

    @media screen and (width: 768px) {
        max-width: 32rem;
        height: rem(320px);

        .swiper-container {
            width: rem(400px);
        }
    }
}
</style>
