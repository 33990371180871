export const CITIES_URL = [
    'https://aap-haval.ru/',
    'https://agat-haval.ru/',
    'https://agat-saransk-haval.ru/',
    'https://agat-vlg-haval.ru/',
    'https://alarm-haval.ru/',
    'https://alarm-haval.ru/',
    'https://alyans-haval.ru/',
    'https://antikorservis.haval.ru/',
    'https://haval-keyauto-omsk.ru/',
    'https://armada-haval.ru/',
    'https://armada-okt-haval.ru/',
    'https://ascar-haval.ru/',
    'https://astrakhan-haval.ru/',
    'https://atctom-haval.ru/',
    'https://dactor-uf-haval.ru/',
    'https://autocentr.haval.ru/',
    'https://autoimport-haval.ru/',
    'https://autopassage.haval.ru/',
    'https://autopremium-haval.ru/',
    'https://avangard-vologda-haval.ru/',
    'https://avilonhaval.ru/',
    'https://avtolider-haval.ru/',
    'https://avtoliga-haval.ru/',
    'https://avtomag-surgut.haval.ru/',
    'https://avtomag.haval.ru/',
    'https://avtomagnv-haval.ru/',
    'https://avtomarket.haval.ru/',
    'https://avtoplus-haval.ru/',
    'https://avtopremier-haval.ru/',
    'https://avtosalon2000.haval.ru/',
    'https://avtotim-haval.ru/',
    'https://baikal-haval.ru/',
    'https://barnaulmotors.haval.ru/',
    'https://bashauto.haval.ru/',
    'https://block-rosko-haval.ru/',
    'https://bolivar-haval.ru/',
    'https://bryansk-haval.ru/',
    'https://demidich.haval.ru/',
    'https://dialog-auto.haval.ru/',
    'https://dialogkazan.haval.ru/',
    'https://donmotors-haval.ru/',
    'https://dynamica-haval.ru/',
    'https://elan-haval.ru/',
    'https://filcom-haval.ru/',
    'https://dactor-otr-haval.ru/',
    'https://avtopremier.haval.ru/',
    'https://forsage-haval.ru/',
    'https://freshauto-krd-haval.ru/',
    'https://freshauto-rnd-haval.ru/',
    'https://haval-freshauto.ru/',
    'https://globus-motors-haval.ru/',
    'https://grand-haval.ru/',
    'https://haval-aleksmotors.ru/',
    'https://haval-apm.ru/',
    'https://haval-asc.ru/',
    'https://haval-asc.ru/',
    'https://haval-avtomir.ru/',
    'https://haval-avtomir.ru/',
    'https://avtomir-arh-haval.ru/',
    'https://haval-avtoruss.ru/',
    'https://haval-avtoruss.ru/',
    'https://haval-kors.ru/',
    'https://haval-korsgroup-kursk.ru/',
    'https://korsgroup-haval.ru/',
    'https://korsgroup.haval.ru/',
    'https://autoug-haval.ru/',
    'https://haval-maximum.ru/',
    'https://ton-auto73-haval.ru/',
    'https://haval-luckymotors.ru/',
    'https://haval-tagil.ru/',
    'https://haval.aspec-auto.ru/',
    'https://haval.autolight.ru/',
    'https://haval.automir-dv.ru/',
    'https://haval.favorit-motors.ru/',
    'https://haval.german-avto.ru/',
    'https://haval.primjera.ru/',
    'https://vm-nsk-haval.ru/',
    'https://haval.stokoney.ru/',
    'https://agat-srt-haval.ru/',
    'https://kanavto-haval.ru/',
    'https://kanavto-haval.ru/',
    'https://keyauto-haval.ru/',
    'https://km-haval.ru/',
    'https://kuntsevo-haval.ru/',
    'https://fcirkutsk-haval.ru/',
    'https://sta.haval.ru/',
    'https://vm-tmn-haval.ru/',
    'https://sura-motors-haval.ru/',
    'https://ta-haval.ru/',
    'https://major.haval.ru/',
    'https://haval-smavto.ru/',
    'https://major.haval.ru/',
    'https://tranzit-haval.ru/',
    'https://major.haval.ru/',
    'https://major.haval.ru/',
    'https://saturn-r-haval.ru/',
    'https://tskmotor-haval.ru/',
    'https://tts.haval.ru/',
    'https://korsgroup-ngsk-haval.ru/',
    'https://vozrojdenie-haval.ru/',
    'https://nnmotors-haval.ru/',
    'https://nnmotors-haval.ru/',
    'agat-krsk-haval.ru/',
    'https://yug-avto-novoross.haval.ru/',
    'https://reginas-haval.ru/',
    'https://reginas-haval.ru/',
    'https://yug-avto.haval.ru/',
    'https://reginasmiass-haval.ru/',
    'https://yug-avto.haval.ru/',
    'https://reginasural-haval.ru/',
    'https://reginasural-haval.ru/',
    'https://magnit.haval.ru/',
    'https://ring-belgorod-haval.ru/',
    'https://yuma-haval.ru/',
    'https://ring-haval.ru/',
    'https://ringauto-haval.ru/',
    'https://autoprodix-haval.ru/',
    'https://ringauto-oskol-haval.ru/',
    'https://kaskad-haval.ru/',
    'https://samara-avtomir.haval.ru/',
    'https://yar-avtomir.haval.ru/',
    'https://sibinpex-haval.ru/',
    'https://medved-haval.ru/',
    'https://sibmotors-haval.ru/',
    'https://spb-lahta-haval.ru/',
    'https://vm-ugra-haval.ru/',
    'https://spb-yug-haval.ru/',
    'https://vm-yamal-haval.ru/',
    'https://apelsin-haval.ru/',
    'https://uservice-haval.ru/',
    'https://rolf-haval.ru/',
    'https://korsgroup-nmsk-haval.ru/',
    'https://aem-haval.ru/',
    'https://fastar-haval.ru/',
    'https://borishof-haval.ru/',
    'https://zelenograd-haval.ru/',
    'https://avantime-haval.ru/',
    'https://haval-avtostyl.ru/',
    'https://agat-ekb-haval.ru/',
    'https://avtotrade-haval.ru/',
    'https://ton-auto-haval.ru/',
    'http://aaamotors-haval.ru/',
    'https://optima-haval.ru/',
    'https://saturn-k-haval.ru/',
    'https://haval-korsgroup-kursk.ru/',
    'https://yug-avto.haval.ru/',
    'https://yug-avto.haval.ru/',
    'https://haval.aspec-auto.ru/',
    'https://haval-avtomir.ru/',
    'https://alarm-haval.ru/',
    'https://haval-avtomir.ru/',
    'https://ringauto-haval.ru/',
    'https://haval-avtoruss.ru/',
    'https://vm-nsk-haval.ru/',
    'https://skm-haval.ru/',
    'https://verra-haval.ru/',
    'https://tts.haval.ru/',
    'https://agat-haval.ru/',
    'https://saturn-r-haval.ru/',
    'https://kaskad-haval.ru/',
    'http://cs-motors-haval.ru/',
    'https://autoprodix-haval.ru/',
    'https://keyauto-krd-haval.ru/',
    'https://avto-premier-haval.ru/',
    'https://borishof-haval.ru/',
    'https://sibinpex-haval.ru/',
    'https://avtomaster-haval.ru/',
    'http://kors-haval.ru/',
    'https://agat-vlg-haval.ru/',
    'https://keyauto-haval.ru/',
    'https://keyauto-kmv-haval.ru/',
    'https://avtomaster-haval.ru/',
    'https://fastar-haval.ru/',
    'https://korsgroup-haval.ru/',
    'https://korsgroup.haval.ru/',
    'https://agat-krv-haval.ru/',
    'https://kanavto-haval.ru/',
    'https://nnmotors-nn-haval.ru/',
    'https://autopassage.haval.ru/',
    'https://spb-yug-haval.ru/',
    'https://haval-maximum.ru/',
    'https://kuntsevo-haval.ru/',
    'https://baikalmotors-haval.ru/',
    'https://major-olimp-havalpro.ru/',
    'https://zelenograd-haval.ru/',
    'https://major-haval.ru/',
    'https://dactor-otr-haval.ru/',
    'https://major-haval.ru/',
    'https://major-haval.ru/',
    'https://kaskad-haval.ru/',
    'https://fcirkutsk-haval.ru/',
    'https://sadko-avto-haval.ru/',
    'https://samara-avtomir.haval.ru/',
    'https://autoprestige-haval.ru',
];
