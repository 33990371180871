<template lang="pug">
    .life-style-gallery
        .top.container
            h2.center Больше Lifestyle
        .gallery(ref="gallery")
            .gallery__wrapper
                .gallery__col.narrow
                    .gallery__item(@click="openModal('1.jpg')")
                        img(:src="getImagePath('1.jpg')" loading="lazy")
                    .gallery__item(@click="openModal('2.jpg')")
                        img(:src="getImagePath('2.jpg')" loading="lazy")

                .gallery__col
                    .gallery__item(@click="openModal('3.jpg')")
                        img(:src="getImagePath('3.jpg')" loading="lazy")

                .gallery__col.narrow
                    .gallery__item(@click="openModal('4.jpg')")
                        img(:src="getImagePath('4.jpg')" loading="lazy")
                    .gallery__item(@click="openModal('8.jpg')")
                        img(:src="getImagePath('8.jpg')" loading="lazy")

                .gallery__col
                    .gallery__item(@click="openModal('6.jpg')")
                        img(:src="getImagePath('6.jpg')" loading="lazy")

                .gallery__col.narrow
                    .gallery__item(@click="openModal('7.jpg')")
                        img(:src="getImagePath('7.jpg')" loading="lazy")
                    .gallery__item(@click="openModal('5.jpg')")
                        img(:src="getImagePath('5.jpg')" loading="lazy")

                .gallery__col
                    .gallery__item(@click="openModal('9.jpg')")
                        img(:src="getImagePath('9.jpg')" loading="lazy")

                .gallery__col.narrow
                    .gallery__item(@click="openModal('10.jpg')")
                        img(:src="getImagePath('10.jpg')" loading="lazy")
                    .gallery__item(@click="openModal('11.jpg')")
                        img(:src="getImagePath('11.jpg')" loading="lazy")

        .nav
            .nav__button.prev(@click="scrollBy('prev')")
            .nav__button.next(@click="scrollBy('next')")
        transition(name="gallery-modal")
            .gallery-modal(
                v-if="openedImage"
                @click.self="closeModal"
            )
                img(:src="openedImage")
                closer-icon.closer(@click="closeModal")

</template>

<script>
import closerIcon from '@/assets/images/icons/closer-cutted.svg?inline';

export default {
    name: 'life-style-gallery',

    components: {
        closerIcon,
    },

    data: () => ({
        galleryScrollbar: null,
        openedImage: null,
    }),

    mounted() {
        this.galleryScrollbar = window.Scrollbar.init(this.$refs.gallery, {
            plugins: { horizontalScroll: { enable: true } },
        });
    },

    methods: {
        scrollBy(direction) {
            let addOffset;
            if (direction === 'prev') {
                addOffset = -window.innerWidth;
            } else {
                addOffset = window.innerWidth;
            }
            if (window.innerWidth > 900) {
                addOffset /= 2;
            }
            const offset = this.galleryScrollbar.offset.x;
            this.galleryScrollbar.scrollTo(offset + addOffset, 0, 1000);
        },

        openModal(name) {
            console.log(name);
            return false;
            // this.openedImage = this.getImagePath(name);
            // window.disableScroll();
        },

        closeModal() {
            this.openedImage = null;
            window.enableScroll();
        },

        getImagePath(name) {
            return require(`@/assets/images/lifeStyleGallery/${name}`);
        },
    },
};
</script>

<style scoped lang="scss">
.life-style-gallery {
    position: relative;

    .top {
        margin-bottom: rem(57px);
    }

    .gallery {
        width: 100%;

        &::v-deep .scrollbar-track {
            display: none !important;
        }

        &__wrapper {
            display: flex;

            height: rem(540px);
        }

        &__col {
            display: flex;
            flex-shrink: 0;
            flex-direction: column;

            width: rem(406px);
            height: inherit;

            &.narrow {
                width: rem(203px);
            }

            &.sub-wide {
                width: rem(500px);
            }

            &.wide {
                width: rem(850px);
            }

            &.mixed {
                width: rem(609px);
            }

            & > .gallery__item {
                height: 100%;

                cursor: default;
            }
        }

        &__row {
            display: flex;

            width: 100%;
            height: inherit;

            .gallery__item {
                height: 100%;
            }
        }

        &__item {
            width: 100%;
            height: 50%;
            overflow: hidden;

            cursor: pointer;

            img {
                display: block;

                width: 100%;
                height: 100%;

                object-fit: cover;

                transition: transform 0.2s;
            }

            @media (hover: hover) {
                &:hover {
                    img {
                        transform: translate3d(0, 0, 0) scale(1.04);
                    }
                }
            }
        }
    }

    .nav {
        position: absolute;
        bottom: rem(33px);
        left: 50%;

        display: flex;

        transform: translateX(-50%);

        &__button {
            width: rem(32px);
            height: rem(32px);
            margin: 0 rem(13px);

            background: url(../../../assets/images/icons/arrow-slider.svg) no-repeat center center /
                30% auto #c4c4c4;
            border-radius: 50%;

            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
                background-color: $gray;
            }

            &.next {
                transform: scaleX(-1);
            }
        }
    }

    .gallery-modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 100vw;

        background: rgba(46, 43, 55, 0.875);

        img {
            max-width: 80%;
            max-height: 80%;

            box-shadow: 0 0 3rem 0 rgb(0 0 0 / 50%);
        }

        .closer {
            position: absolute;
            top: rem(48px);
            right: rem(48px);

            width: rem(24px);

            cursor: pointer;
            transition: box-shadow 0.1s;

            path {
                fill: #fff;
            }

            &:hover {
                box-shadow: 0 0 0 rem(16px) rgba(#fff, 0.1), inset 0 0 0 rem(10px) rgba(#fff, 0.1);
            }
        }

        &-enter {
            opacity: 0;

            img {
                transform: translate3d(0, rem(16px), 0);
                opacity: 0;
            }
        }

        &-enter-active {
            transition: all 0.6s, opacity 0.3s;

            img {
                transition: 0.5s 0.1s;
            }
        }

        &-leave-active {
            opacity: 0;
            transition: opacity 0.3s;
        }
    }

    @include mobile_or_P {
        .top {
            margin-bottom: rem(39px);
        }

        .gallery {
            &__wrapper {
                height: rem(390px);
            }

            &__col {
                width: rem(292px);

                &.narrow {
                    width: rem(146px);
                }

                &.sub-wide {
                    width: rem(360px);
                }

                &.wide {
                    width: rem(614px);
                }

                &.mixed {
                    width: rem(438px);
                }
            }
        }

        .nav {
            position: static;

            transform: initial;

            &__button {
                position: absolute;
                top: 53%;
                z-index: 1;

                margin: 0;

                &.prev {
                    left: rem(7px);
                }

                &.next {
                    right: rem(7px);
                }
            }
        }
    }
}
</style>
